import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";

const ResetPasswordService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  async resetPassword(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/resetuserpassword`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async resetPasswordEmail(formEmail) {
    const requestObj = {
      email: formEmail,
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/auth/sendpasswordresetemail`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};

export default ResetPasswordService;
