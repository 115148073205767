import styles from "./buttonRounded.module.scss";
const ButtonRounded = (props) => {
  const { className, isReversed, borderless, title, url, size, variant, isHeroButton = false } = props;
  return (
    <a
      className={`${isHeroButton ? styles.heroButton : styles.buttonRounded} ${isReversed ? styles.reversed : ""} ${
        borderless ? styles.borderless : ""
      } ${className ? className : ""} ${size ? styles[size] : ""} ${variant ? styles[variant] : ""}`}
      href={url ? url : ""}
    >
      <span className={styles.buttonLabel}>{title}</span>
    </a>
  );
};

export default ButtonRounded;
