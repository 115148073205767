import React, { createContext, useContext, useReducer } from "react";
import { ManageFaqService } from "../services";

const ManageFaqState = createContext();
const ManageFaqDispatch = createContext();

const EVENT_TYPES = {
  UPDATE: "update",
  INITIAL_UPDATE: "initial_update",
  NEW_FAQ_LOADING: "new_faq_loading",
  DELETE_FAQ_LOADING: "delete_faq_loading",
  SAVE_FAQ_LOADING: "save_faq_loading",
  EDIT_FAQ_SUCCESS: "edit_faq_success",
  NEW_FAQ_SUCCESS: "new_faq_success",
  DELETE_FAQ_SUCCESS: "delete_faq_success",
  CLEAR_MANAGE_FAQ_ERRORS: "clear_manage_faq_errors",
  MANAGE_FAQ_ERROR: "manage_faq_error",
  CLEAR_ALL: "clear_all",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      faqId: values.row.id,
      category: values.row.category,
      question: values.row.question,
      answer: values.row.answer,
    };
  },
  [EVENT_TYPES.NEW_FAQ_LOADING]: (state) => {
    return {
      ...state,
      newFaqSuccess: false,
      newFaqLoading: true,
      manageFaqError: false,
    };
  },
  [EVENT_TYPES.SAVE_FAQ_LOADING]: (state) => {
    return {
      ...state,
      editFaqSuccess: false,
      newFaqSuccess: false,
      saveFaqLoading: true,
    };
  },
  [EVENT_TYPES.DELETE_FAQ_LOADING]: (state) => {
    return {
      ...state,
      deleteFaqSuccess: false,
      deleteFaqLoading: true,
      manageFaqError: false,
    };
  },
  [EVENT_TYPES.NEW_FAQ_SUCCESS]: (state) => {
    return {
      ...state,
      newFaqSuccess: true,
      newFaqLoading: false,
      manageFaqError: false,
    };
  },
  [EVENT_TYPES.EDIT_FAQ_SUCCESS]: (state) => {
    return {
      ...state,
      editFaqSuccess: true,
      newFaqSuccess: false,
      saveFaqLoading: false,
    };
  },
  [EVENT_TYPES.DELETE_FAQ_SUCCESS]: (state) => {
    return {
      ...state,
      deleteFaqSuccess: true,
      deleteFaqLoading: false,
      manageFaqError: false,
    };
  },
  [EVENT_TYPES.MANAGE_FAQ_ERROR]: (state, event) => {
    const { manageFaqError } = event.payload;
    return {
      ...state,
      manageFaqError,
      editFaqSuccess: false,
      newFaqSuccess: false,
      saveFaqLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_MANAGE_FAQ_ERRORS]: (state) => {
    return {
      ...state,
      manageFaqError: "",
      editFaqSuccess: false,
      newFaqSuccess: false,
      saveFaqLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ALL]: (state) => {
    return {
      ...state,
      faqId: "",
      category: "",
      question: "",
      answer: "",
      manageFaqError: "",
      editFaqSuccess: false,
      saveFaqLoading: false,
      deleteFaqLoading: false,
      deleteFaqSuccess: false,
      newFaqLoading: false,
      newFaqSuccess: false,
    };
  },
};

const INITIAL_STATE = {
  faqId: "",
  category: "",
  question: "",
  answer: "",
  sortOrder: "",
  manageFaqError: "",
  editFaqSuccess: false,
  saveFaqLoading: false,
  deleteFaqLoading: false,
  deleteFaqSuccess: false,
  newFaqLoading: false,
  newFaqSuccess: false,
};

const ManageFaqReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ManageFaqProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManageFaqReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };

  const handleClearAll = (event) => {
    dispatch({ type: EVENT_TYPES.CLEAR_ALL });
  };

  const handleInitialUpdate = (values) => {
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE, payload: { values } });
  };

  const handleSaveExistingFaq = () => {
    const { faqId, category, question, answer } = state;
    dispatch({ type: EVENT_TYPES.SAVE_FAQ_LOADING });
    ManageFaqService.updateFaq(faqId, category, question, answer)
      .then(() => {
        dispatch({ type: EVENT_TYPES.EDIT_FAQ_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_FAQ_ERROR,
          payload: {
            manageFaqError: message,
          },
        });
      });
  };
  const handleUpdateFaqsDragDrop = (request) => {
    //const { faqId, category, question, answer } = state;
    dispatch({ type: EVENT_TYPES.SAVE_FAQ_LOADING });
    ManageFaqService.updateFaqs(request)
      .then(() => {
        dispatch({ type: EVENT_TYPES.EDIT_FAQ_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_FAQ_ERROR,
          payload: {
            manageFaqError: message,
          },
        });
      });
  };

  const handleAddFaq = (faqId) => {
    const { category, question, answer } = state;
    dispatch({ type: EVENT_TYPES.NEW_FAQ_LOADING });
    ManageFaqService.saveFaq(category, question, answer)
      .then(() => {
        dispatch({ type: EVENT_TYPES.NEW_FAQ_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_FAQ_ERROR,
          payload: {
            manageFaqError: message,
          },
        });
      });
  };
  const handleDeleteFaq = (faqId) => {
    dispatch({ type: EVENT_TYPES.DELETE_FAQ_LOADING });
    ManageFaqService.deleteFaq(faqId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_FAQ_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_FAQ_ERROR,
          payload: {
            manageFaqError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.MANAGE_FAQ_ERROR,
      payload: {
        manageFaqError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_FAQ_ERRORS });
  };

  const events = {
    onUpdate: handleUpdate,
    onUpdateFaqsDragDrop: handleUpdateFaqsDragDrop,
    onInitialUpdate: handleInitialUpdate,
    onError: handleError,
    onSaveExistingFaq: handleSaveExistingFaq,
    onAddFaq: handleAddFaq,
    onDeleteFaq: handleDeleteFaq,
    onClearErrors: handleClearErrors,
    onClearAll: handleClearAll,
  };

  return (
    <ManageFaqState.Provider value={state}>
      <ManageFaqDispatch.Provider value={events}>{children}</ManageFaqDispatch.Provider>
    </ManageFaqState.Provider>
  );
};

const useManageFaqState = () => {
  const context = useContext(ManageFaqState);

  if (context === undefined) {
    throw new Error("useManageFaqState must be used within an ManageFaqProvider");
  }

  return context;
};

const useManageFaqDispatch = () => {
  const context = useContext(ManageFaqDispatch);

  if (context === undefined) {
    throw new Error("useManageFaqDispatch must be used within an ManageFaqProvider");
  }

  return context;
};

export { ManageFaqProvider, useManageFaqState, useManageFaqDispatch };
