import React, { createContext, useContext, useReducer } from "react";
import { HirService } from "../services";
import { saveAs } from "file-saver";

const HirState = createContext();
const HirDispatch = createContext();

const sanitizeSearchHirs = (data) => {
  let cleanedData = Object.assign({}, data);
  let totalMissingItems = 0;
  if (cleanedData.hits === null) {
    cleanedData.hits = [];
  }
  let cleanedHits = cleanedData.hits.map((item, index) => {
    if (item.title !== null && item.title.length > 0) {
      if (item.updatedDate === null || item.updatedDate === undefined) {
        item.updatedDate = "";
      }
      return item;
    } else {
      totalMissingItems++;
    }
  });

  if (totalMissingItems) {
    cleanedData.totalHits = cleanedData.totalHits - totalMissingItems;
  }
  return cleanedData;
};
const sanitizePreviewHirs = (data) => {
  let cleanedData = Object.assign({}, data);
  let totalMissingItems = 0;
  let cleanedHits = cleanedData.hiRs.map((item, index) => {
    if (item.title !== null && item.title.length > 0) {
      if (item.updatedDate === null || item.updatedDate === undefined) {
        item.updatedDate = "";
      }
      return item;
    } else {
      totalMissingItems++;
    }
  });

  if (totalMissingItems) {
    cleanedData.totalHits = cleanedData.totalHits - totalMissingItems;
  }
  return cleanedData;
};
const EVENT_TYPES = {
  UPDATE: "update",
  INITIAL_UPDATE: "initial_update",
  INITIAL_DASHBOARD_UPDATE: "initial_dashboard_update",
  EDIT_LOADING: "edit_loading",
  EDIT_SUCCESS: "edit_success",
  UPLOAD_SUCCESS: "upload_success",
  DELETE_SUCCESS: "delete_success",
  DOWNLOAD_SUCCESS: "download_success",
  DOWNLOAD_HIR_BY_ID_LOADING: "download_hir_by_id_loading",
  DOWNLOAD_HIR_BY_ID_SUCCESS: "download_hir_by_id_success",
  SAVE_HIR_TOPIC_LOADING: "save_hir_topic_loading",
  SAVE_HIR_TOPIC_SUCCESS: "save_hir_topic_success",
  UPDATE_HIR_TOPIC_LOADING: "update_hir_topic_loading",
  UPDATE_HIR_TOPIC_SUCCESS: "update_hir_topic_success",
  DELETE_HIR_TOPIC_LOADING: "delete_hir_topic_loading",
  DELETE_HIR_TOPIC_SUCCESS: "delete_hir_topic_success",
  GET_HIR_SUCCESS: "get_hir_success",
  GET_HIR_LOADING: "get_hir_loading",
  GET_HIR_TOPICS_SUCCESS: "get_hir_topics_success",
  GET_HIR_TOPICS_LOADING: "get_hir_topics_loading",
  HIR_UPDATE_DASHBOARD_TOPICS_REQUEST: "hir_update_dashboard_topics_request",
  HIR_UPDATE_SEARCH_REQUEST: "get_hir_search_request",
  HIR_UPDATE_PREVIEW_REQUEST: "get_hir_preview_request",
  HIR_SEARCH_SUCCESS: "hir_search_success",
  HIR_SEARCH_LOADING: "hir_search_loading",
  HIR_PREVIEW_SUCCESS: "hir_preview_success",
  HIR_PREVIEW_LOADING: "hir_preview_loading",
  HIR_UPDATE_SEARCH_TOPIC_REQUEST: "get_hir_search_topic_request",
  HIR_SEARCH_TOPIC_SUCCESS: "hir_search_topic_success",
  HIR_SEARCH_TOPIC_LOADING: "hir_search_topic_loading",
  CLEAR_ERRORS: "clear_errors",
  HIR_CLEAR_ALL: "hir_clear_all",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      id: values.row.id,
      title: values.row.title,
      description: values.row.description,
      //file: values.row.file,
    };
  },
  [EVENT_TYPES.INITIAL_DASHBOARD_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      titleOfTopic: values.row.titleOfTopic,
      ihsiCategories: values.row.ihsiCategories,
      publicationDate: values.row.publicationDate,
      hssid: values.row.hssid,
      //file: values.row.file,
    };
  },
  [EVENT_TYPES.HIR_UPDATE_DASHBOARD_TOPICS_REQUEST]: (state, event) => {
    const { searchRequest } = event.payload;
    return {
      ...state,
      currentHirDashboardTopicsRequest: searchRequest,
    };
  },
  [EVENT_TYPES.HIR_UPDATE_SEARCH_REQUEST]: (state, event) => {
    const { searchRequest } = event.payload;
    return {
      ...state,
      currentHirSearchRequest: searchRequest,
    };
  },
  [EVENT_TYPES.HIR_UPDATE_PREVIEW_REQUEST]: (state, event) => {
    const { searchRequest } = event.payload;
    return {
      ...state,
      currentHirPreviewRequest: searchRequest,
    };
  },
  [EVENT_TYPES.HIR_UPDATE_SEARCH_TOPIC_REQUEST]: (state, event) => {
    const { searchRequest } = event.payload;
    return {
      ...state,
      currentHirSearchTopicRequest: searchRequest,
    };
  },
  [EVENT_TYPES.EDIT_LOADING]: (state) => {
    return {
      ...state,
      hirEditLoading: true,
      hirEditSuccess: false,
    };
  },
  [EVENT_TYPES.EDIT_SUCCESS]: (state) => {
    return {
      ...state,
      hirEditLoading: false,
      hirEditSuccess: true,
    };
  },
  [EVENT_TYPES.UPLOAD_SUCCESS]: (state) => {
    return {
      ...state,
      hirUploadSuccess: true,
    };
  },
  [EVENT_TYPES.SAVE_HIR_TOPIC_LOADING]: (state) => {
    return {
      ...state,
      saveHirTopicLoading: true,
      saveHirTopicSuccess: false,
    };
  },
  [EVENT_TYPES.SAVE_HIR_TOPIC_SUCCESS]: (state) => {
    return {
      ...state,
      saveHirTopicLoading: false,
      saveHirTopicSuccess: true,
    };
  },
  [EVENT_TYPES.UPDATE_HIR_TOPIC_LOADING]: (state) => {
    return {
      ...state,
      updateHirTopicLoading: true,
      updateHirTopicSuccess: false,
    };
  },
  [EVENT_TYPES.UPDATE_HIR_TOPIC_SUCCESS]: (state) => {
    return {
      ...state,
      updateHirTopicLoading: false,
      updateHirTopicSuccess: true,
    };
  },
  [EVENT_TYPES.DELETE_HIR_TOPIC_LOADING]: (state) => {
    return {
      ...state,
      deleteHirTopicLoading: true,
      deleteHirTopicSuccess: false,
    };
  },
  [EVENT_TYPES.DELETE_HIR_TOPIC_SUCCESS]: (state) => {
    return {
      ...state,
      deleteHirTopicLoading: false,
      deleteHirTopicSuccess: true,
    };
  },
  [EVENT_TYPES.DELETE_SUCCESS]: (state) => {
    return {
      ...state,
      hirDeleteSuccess: true,
    };
  },
  [EVENT_TYPES.DOWNLOAD_SUCCESS]: (state) => {
    return {
      ...state,
      hirDownloadSuccess: true,
    };
  },

  [EVENT_TYPES.HIR_SEARCH_SUCCESS]: (state, event) => {
    let { data } = event.payload;
    const newData = sanitizeSearchHirs(data);

    return {
      ...state,
      hirSearchSuccess: true,
      hirSearchLoading: false,
      hirSearchData: newData,
    };
  },
  [EVENT_TYPES.HIR_PREVIEW_SUCCESS]: (state, event) => {
    let { data } = event.payload;
    const newData = sanitizePreviewHirs(data);

    return {
      ...state,
      hirPreviewSuccess: true,
      hirPreviewLoading: false,
      hirPreviewData: newData,
    };
  },
  [EVENT_TYPES.HIR_SEARCH_TOPIC_SUCCESS]: (state, event) => {
    return {
      ...state,
      hirSearchTopicSuccess: true,
      hirSearchTopicLoading: false,
      hirSearchTopicData: event.payload.data,
    };
  },
  [EVENT_TYPES.DOWNLOAD_HIR_BY_ID_SUCCESS]: (state, event) => {
    const { downloadHirByIdData } = event.payload;
    return {
      ...state,
      downloadHirByIdSuccess: true,
      downloadHirByIdLoading: false,
      downloadHirByIdData,
    };
  },
  [EVENT_TYPES.DOWNLOAD_HIR_BY_ID_LOADING]: (state) => {
    return {
      ...state,
      downloadHirByIdSuccess: false,
      downloadHirByIdLoading: true,
    };
  },
  [EVENT_TYPES.GET_HIR_TOPICS_SUCCESS]: (state, event) => {
    const { hirTopicsData } = event.payload;
    return {
      ...state,
      getHirTopicsSuccess: true,
      getHirTopicsLoading: false,
      hirTopicsData,
    };
  },
  [EVENT_TYPES.GET_HIR_TOPICS_LOADING]: (state) => {
    return {
      ...state,
      getHirTopicsSuccess: false,
      getHirTopicsLoading: true,
    };
  },
  [EVENT_TYPES.GET_HIR_SUCCESS]: (state, event) => {
    const { hirData } = event.payload;
    return {
      ...state,
      getHirSuccess: true,
      getHirLoading: false,
      hirData,
    };
  },
  [EVENT_TYPES.GET_HIR_LOADING]: (state) => {
    return {
      ...state,
      getHirSuccess: false,
      getHirLoading: true,
    };
  },
  [EVENT_TYPES.HIR_SEARCH_LOADING]: (state) => {
    return {
      ...state,
      hirSearchSuccess: false,
      hirSearchLoading: true,
    };
  },
  [EVENT_TYPES.HIR_PREVIEW_LOADING]: (state) => {
    return {
      ...state,
      hirPreviewSuccess: false,
      hirPreviewLoading: true,
    };
  },
  [EVENT_TYPES.HIR_SEARCH_TOPIC_LOADING]: (state) => {
    return {
      ...state,
      hirSearchTopicSuccess: false,
      hirSearchTopicLoading: true,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { hirError } = event.payload;
    return {
      ...state,
      hirError,
      hirUploadSuccess: false,
      getHirLoading: false,
      getHirSuccess: false,
      hirEditLoading: false,
      hirEditSuccess: false,
      hirDownloadSuccess: false,
      hirSearchSuccess: false,
      hirSearchLoading: false,
      hirSearchTopicSuccess: false,
      hirSearchTopicLoading: false,
      hirDeleteSuccess: false,
      saveHirTopicLoading: false,
      saveHirTopicSuccess: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      hirError: "",
      hirUploadSuccess: false,
      hirEditLoading: false,
      hirEditSuccess: false,
      getHirSuccess: false,
      hirDownloadSuccess: false,
      downloadHirByIdSuccess: false,
      hirSearchSuccess: false,
      hirSearchLoading: false,
      saveHirTopicLoading: false,
      saveHirTopicSuccess: false,
      updateHirTopicLoading: false,
      updateHirTopicSuccess: false,
    };
  },
  [EVENT_TYPES.HIR_CLEAR_ALL]: (state, event) => {
    const { INITIAL_STATE } = event.payload;
    return {
      ...state,
      ...INITIAL_STATE,
    };
  },
};

let defaultHirSearchRequest = {
  size: 1000,
  from: 0,
  sortOrder: "asc",
  sortBy: "title",
  updatedDateFrom: new Date().toISOString(),
  searchPhrase: "*",
};
let defaultHirPreviewRequest = {
  size: 1000,
  from: 0,
  sortOrder: "desc",
  sortBy: "updated_date",
};
let defaultHirTopicsRequest = {
  size: 1000,
  from: 0,
  orderBy: "title_of_topic",
  sortOrder: "asc",
  showArchived: false,
  showDeleted: false,
};
let defaultHirSearchTopicRequest = {
  size: 20,
  from: 0,
  orderBy: "title_of_topic",
  sortOrder: "asc",
};
const INITIAL_STATE = {
  id: "",
  hssid: "",
  title: "",
  description: "",
  archived: false,
  file: "",
  hirError: "",
  titleOfTopic: "",
  ihsiCategories: [],
  publicationDate: "",
  saveHirTopicLoading: false,
  saveHirTopicSuccess: false,
  updateHirTopicLoading: false,
  updateHirTopicSuccess: false,
  deleteHirTopicLoading: false,
  deleteHirTopicSuccess: false,
  defaultHirSearchRequest: defaultHirSearchRequest,
  defaultHirPreviewRequest: defaultHirPreviewRequest,
  defaultHirSearchTopicRequest: defaultHirSearchTopicRequest,
  defaultHirTopicsRequest: defaultHirTopicsRequest,
  hirUploadSuccess: false,
  hirEditLoading: false,
  hirEditSuccess: false,
  getHirSuccess: false,
  getHirLoading: false,
  downloadHirByIdSuccess: false,
  downloadHirByIdLoading: false,
  hirDownloadSuccess: false,
  hirData: {},
  hirTopicsData: {},
  hirSearchSuccess: true,
  hirSearchLoading: false,
  hirPreviewSuccess: false,
  hirPreviewLoading: false,
  hirSearchData: {},
  hirPreviewData: {},
  hirSearchTopicSuccess: true,
  hirSearchTopicLoading: false,
  hirSearchTopicData: {},
  currentHirSearchRequest: {},
  currentHirPreviewRequest: {},
  currentHirSearchTopicRequest: {},
  currentHirDashboardTopicsRequest: {},
  hirDeleteSuccess: false,
};

var savePdf = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (url, fileName) {
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const buildPdf = (base64Data) => {
  let base64str = base64Data;
  let binary = atob(base64str.replace(/\s/g, ""));
  let len = binary.length;
  let buffer = new ArrayBuffer(len);
  let view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  let blob = new Blob([view], { type: "application/pdf" });
  let url = URL.createObjectURL(blob);
  return url;
};

const HirReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const HirProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HirReducer, INITIAL_STATE);

  const handleUpdate = (event, isFile = false) => {
    handleClearErrors();
    const name = event.target.name;
    const value = isFile ? event.target.files[0] : event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };

  const handleInitialUpdate = (values) => {
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE, payload: { values } });
  };
  const handleInitialDashboardUpdate = (values) => {
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_DASHBOARD_UPDATE, payload: { values } });
  };
  const handleHirEdit = () => {
    const { id, title, description, file } = state;
    dispatch({ type: EVENT_TYPES.EDIT_LOADING });
    let formData = new FormData();

    formData.append("Id", id);
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("File", file);

    HirService.editHir(formData)
      .then(() => {
        dispatch({ type: EVENT_TYPES.EDIT_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };

  const handleHirUpload = () => {
    const { id, title, description, file } = state;

    let formData = new FormData();

    //formData.append("HssId", id);
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("File", file);

    HirService.uploadHir(formData)
      .then(() => {
        dispatch({ type: EVENT_TYPES.UPLOAD_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handleSaveHirTopic = (request) => {
    dispatch({ type: EVENT_TYPES.SAVE_HIR_TOPIC_LOADING });
    HirService.saveHirTopic(request)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_HIR_TOPIC_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handleUpdateHirTopic = (request) => {
    dispatch({ type: EVENT_TYPES.UPDATE_HIR_TOPIC_LOADING });
    HirService.updateHirTopic(request)
      .then(() => {
        dispatch({ type: EVENT_TYPES.UPDATE_HIR_TOPIC_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handleDeleteHirTopic = (id) => {
    dispatch({ type: EVENT_TYPES.DELETE_HIR_TOPIC_LOADING });
    HirService.deleteHirTopic(id)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_HIR_TOPIC_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handleGetHirTopics = (request) => {
    handleHirUpdatePreviewRequest(request);
    dispatch({ type: EVENT_TYPES.GET_HIR_TOPICS_LOADING });
    HirService._hirTopicsRequest(request);
    HirService.getHirTopics(request)
      .then((hirTopicsData) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.GET_HIR_TOPICS_SUCCESS, payload: { hirTopicsData } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetHir = (skip, take, showDeletedOnly) => {
    dispatch({ type: EVENT_TYPES.GET_HIR_LOADING });
    HirService.getHir(skip, take, showDeletedOnly)
      .then((hirData) => {
        dispatch({ type: EVENT_TYPES.GET_HIR_SUCCESS, payload: { hirData } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handleHirUpdateSearchRequest = (searchRequest) => {
    HirService.setCurrentHirSearchRequest(searchRequest);
    dispatch({ type: EVENT_TYPES.HIR_UPDATE_SEARCH_REQUEST, payload: { searchRequest } });
  };
  const handleHirUpdateDashboardTopicsRequest = (searchRequest) => {
    HirService.setCurrentHirDashboardTopicsRequest(searchRequest);
    dispatch({ type: EVENT_TYPES.HIR_UPDATE_DASHBOARD_TOPICS_REQUEST, payload: { searchRequest } });
  };

  const handleHirUpdatePreviewRequest = (searchRequest) => {
    HirService.setCurrentHirPreviewRequest(searchRequest);
    dispatch({ type: EVENT_TYPES.HIR_UPDATE_PREVIEW_REQUEST, payload: { searchRequest } });
  };

  const handleHirUpdateSearchTopicRequest = (searchRequest) => {
    HirService.setCurrentHirSearchTopicRequest(searchRequest);
    dispatch({ type: EVENT_TYPES.HIR_UPDATE_SEARCH_TOPIC_REQUEST, payload: { searchRequest } });
  };

  const handleSearchHirs = (request) => {
    handleHirUpdateSearchRequest(request);
    dispatch({ type: EVENT_TYPES.HIR_SEARCH_LOADING });
    HirService._hirSearchRequest(request);
    HirService.retrieveHirSearchResults(request)
      .then((data) => {
        if (data.error) {
          dispatch({
            type: EVENT_TYPES.ERROR,
            payload: {
              hirError: data.error,
              data,
            },
          });
          return Promise.resolve();
        } else {
          dispatch({ type: EVENT_TYPES.HIR_SEARCH_SUCCESS, payload: { data } });
          return Promise.resolve();
        }
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };
  const handlePreviewHirs = (request) => {
    handleHirUpdatePreviewRequest(request);
    dispatch({ type: EVENT_TYPES.HIR_PREVIEW_LOADING });
    HirService._hirPreviewRequest(request);
    HirService.retrieveHirPreviewResults(request)
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.HIR_PREVIEW_SUCCESS, payload: { data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleSearchTopicHirs = (request) => {
    handleHirUpdateSearchTopicRequest(request);
    dispatch({ type: EVENT_TYPES.HIR_SEARCH_TOPIC_LOADING });
    HirService._hirSearchTopicRequest(request);
    HirService.retrieveHirSearchTopicResults(request)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.HIR_SEARCH_TOPIC_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleHirDownload = (data, filename, enablePreview = true) => {
    let url = buildPdf(data);
    // let blobData = data.blob();
    // let saveData = saveAs(blobData, `${filename}.pdf`);
    //window.open(url, "_blank");
    if (enablePreview) {
      //savePdf(url, filename);
      window.open(url, "_blank");
    } else {
      saveAs(url, `${filename}.pdf`);
    }
  };

  const handleHirDownloadById = (id, title) => {
    dispatch({ type: EVENT_TYPES.DOWNLOAD_HIR_BY_ID_LOADING });
    HirService.hirDownloadById(id)
      .then((hirData) => {
        if (
          hirData === null ||
          hirData === undefined ||
          hirData.hir === null ||
          hirData.hir === undefined ||
          hirData.hir.fileData === null ||
          hirData.hir.fileData === undefined
        ) {
          dispatch({
            type: EVENT_TYPES.ERROR,
            payload: {
              hirError: "No file data Found",
            },
          });
          return Promise.resolve();
        } else {
          dispatch({ type: EVENT_TYPES.DOWNLOAD_HIR_BY_ID_SUCCESS, payload: { hirData } });
          let url = buildPdf(hirData.hir.fileData);
          // let blobData = data.blob();
          // let saveData = saveAs(blobData, `${filename}.pdf`);
          //window.open(url, "_blank");
          //if (enablePreview) {
          //savePdf(url, filename);
          //window.open(url, "_self");
          saveAs(url, `${title}.pdf`);
          //} else {
          //  saveAs(url, `${filename}.pdf`);
          //}
          return Promise.resolve();
        }
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        hirError: errorMsg,
      },
    });
  };
  const handleHirClearAll = (isSelectAll) => {
    const { hirData, currentHirSearchRequest, hirSearchData } = state;

    HirService._hirData({});
    HirService._hirSearchRequest({});
    HirService._hirSearchData({});

    dispatch({
      type: EVENT_TYPES.HIR_CLEAR_ALL,
      payload: { INITIAL_STATE },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };
  const handleHirDelete = (hirId) => {
    HirService.deleteHir(hirId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            hirError: message,
          },
        });
      });
  };

  const events = {
    onUpdate: handleUpdate,
    onInitialUpdate: handleInitialUpdate,
    onInitialDashboardUpdate: handleInitialDashboardUpdate,
    onError: handleError,
    onDeleteHirTopic: handleDeleteHirTopic,
    onSaveHirTopic: handleSaveHirTopic,
    onUpdateHirTopic: handleUpdateHirTopic,
    onGetHir: handleGetHir,
    onGetHirTopics: handleGetHirTopics,
    onHirEdit: handleHirEdit,
    onHirUpload: handleHirUpload,
    onHirDownload: handleHirDownload,
    onHirDownloadById: handleHirDownloadById,
    onClearErrors: handleClearErrors,
    onSearchHirs: handleSearchHirs,
    onPreviewHirs: handlePreviewHirs,
    onSearchTopicHirs: handleSearchTopicHirs,
    onHirClearAll: handleHirClearAll,
    onHirDelete: handleHirDelete,
    onHirUpdateSearchRequest: handleHirUpdateSearchRequest,
    onHirUpdatePreviewRequest: handleHirUpdatePreviewRequest,
    onHirUpdateSearchTopicRequest: handleHirUpdateSearchTopicRequest,
  };

  return (
    <HirState.Provider value={state}>
      <HirDispatch.Provider value={events}>{children}</HirDispatch.Provider>
    </HirState.Provider>
  );
};

const useHirState = () => {
  const context = useContext(HirState);

  if (context === undefined) {
    throw new Error("useHirState must be used within an HirProvider");
  }

  return context;
};

const useHirDispatch = () => {
  const context = useContext(HirDispatch);

  if (context === undefined) {
    throw new Error("useHirDispatch must be used within an HirProvider");
  }

  return context;
};

export { HirProvider, useHirState, useHirDispatch };
