import React, { createContext, useContext, useReducer } from "react";
import { ManageGlossaryService, GlossaryService } from "../services";
import { useGlossaryState, useGlossaryDispatch } from "../providers/glossaryProvider";

const ManageGlossaryState = createContext();
const ManageGlossaryDispatch = createContext();
const EVENT_TYPES = {
  UPDATE: "update",
  INITIAL_UPDATE: "initial_update",
  NEW_GLOSSARY_LOADING: "new_glossary_loading",
  NEW_GLOSSARY_SUCCESS: "new_glossary_success",
  SAVE_GLOSSARY_LOADING: "save_glossary_loading",
  SAVE_GLOSSARY_SUCCESS: "SAVE_GLOSSARY_SUCCESS",
  DELETE_GLOSSARY_LOADING: "delete_glossary_loading",
  DELETE_GLOSSARY_SUCCESS: "delete_glossary_success",
  CLEAR_MANAGE_GLOSSARY_ERRORS: "clear_manage_glossary_errors",
  MANAGE_GLOSSARY_ERROR: "manage_glossary_error",
  CLEAR_ALL: "clear_all",
};
const EVENTS = {
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      recordSection: values.row.recordSection,
      fieldName: values.row.fieldName,
      fieldDescription: values.row.fieldDescription,
      sourceOfData: values.row.sourceOfData,
      glossaryId: values.row.id,
    };
  },
  [EVENT_TYPES.NEW_GLOSSARY_LOADING]: (state) => {
    return {
      ...state,
      newGlossarySuccess: false,
      newGlossaryLoading: true,
      manageGlossaryError: false,
    };
  },
  [EVENT_TYPES.SAVE_GLOSSARY_LOADING]: (state) => {
    return {
      ...state,
      saveGlossarySuccess: false,
      newGlossarySuccess: false,
      saveGlossaryLoading: true,
    };
  },
  [EVENT_TYPES.DELETE_GLOSSARY_LOADING]: (state, event) => {
    const { glossaryId } = event.payload;
    return {
      ...state,
      deleteGlossarySuccess: false,
      deleteGlossaryLoading: true,
      manageGlossaryError: false,
      deleteGlossaryId: glossaryId,
    };
  },
  [EVENT_TYPES.DELETE_GLOSSARY_SUCCESS]: (state) => {
    return {
      ...state,
      deleteGlossarySuccess: true,
      deleteGlossaryLoading: false,
      manageGlossaryError: false,
      deleteGlossaryId: null,
    };
  },
  [EVENT_TYPES.NEW_GLOSSARY_SUCCESS]: (state) => {
    return {
      ...state,
      newGlossarySuccess: true,
      newGlossaryLoading: false,
      manageGlossaryError: false,
    };
  },
  [EVENT_TYPES.SAVE_GLOSSARY_SUCCESS]: (state) => {
    return {
      ...state,
      saveGlossarySuccess: true,
      newGlossarySuccess: false,
      saveGlossaryLoading: false,
    };
  },
  [EVENT_TYPES.MANAGE_GLOSSARY_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      saveGlossarySuccess: false,
      newGlossarySuccess: false,
      saveGlossaryLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_MANAGE_GLOSSARY_ERRORS]: (state) => {
    return {
      ...state,
      manageGlossaryError: "",
      saveGlossarySuccess: false,
      newGlossarySuccess: false,
      saveGlossaryLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ALL]: (state) => {
    return {
      ...state,
      recordSection: "",
      fieldName: "",
      fieldDescription: "",
      sourceOfData: "",
      glossaryId: "",
      manageGlossaryError: "",
      saveGlossarySuccess: false,
      saveGlossaryLoading: false,
      deleteGlossaryLoading: false,
      deleteGlossarySuccess: false,
      deleteGlossaryId: null,
      newGlossaryLoading: false,
      newGlossarySuccess: false,
    };
  },
};

const INITIAL_STATE = {
  recordSection: "",
  fieldName: "",
  fieldDescription: "",
  sourceOfData: "",
  glossaryId: "",
  manageGlossaryError: "",
  saveGlossarySuccess: false,
  saveGlossaryLoading: false,
  deleteGlossaryLoading: false,
  deleteGlossarySuccess: false,
  deleteGlossaryId: null,
  newGlossaryLoading: false,
  newGlossarySuccess: false,
};

const ManageGlossaryReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ManageGlossaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManageGlossaryReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };
  const handleClearAll = (event) => {
    dispatch({ type: EVENT_TYPES.CLEAR_ALL });
  };

  const handleInitialUpdate = (values) => {
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE, payload: { values } });
  };

  const handleSaveExistingGlossary = () => {
    const { recordSection, fieldName, fieldDescription, sourceOfData, glossaryId } = state;
    dispatch({ type: EVENT_TYPES.SAVE_GLOSSARY_LOADING });
    ManageGlossaryService.updateGlossary(recordSection, fieldName, fieldDescription, sourceOfData, glossaryId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_GLOSSARY_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_GLOSSARY_ERROR,
          payload: {
            manageGlossaryError: message,
          },
        });
      });
  };
  const handleAddGlossary = (glossaryId) => {
    const { recordSection, fieldName, fieldDescription, sourceOfData } = state;
    dispatch({ type: EVENT_TYPES.NEW_GLOSSARY_LOADING });
    ManageGlossaryService.saveGlossary(recordSection, fieldName, fieldDescription, sourceOfData)
      .then(() => {
        dispatch({ type: EVENT_TYPES.NEW_GLOSSARY_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_GLOSSARY_ERROR,
          payload: {
            manageGlossaryError: message,
          },
        });
      });
  };
  const handleDeleteGlossary = (glossaryId) => {
    dispatch({ type: EVENT_TYPES.DELETE_GLOSSARY_LOADING, payload: { glossaryId } });
    ManageGlossaryService.deleteGlossary(glossaryId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_GLOSSARY_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_GLOSSARY_ERROR,
          payload: {
            manageGlossaryError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.MANAGE_GLOSSARY_ERROR,
      payload: {
        manageGlossaryError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_GLOSSARY_ERRORS });
  };

  const events = {
    onUpdate: handleUpdate,
    onInitialUpdate: handleInitialUpdate,
    onError: handleError,
    onSaveExistingGlossary: handleSaveExistingGlossary,
    onAddGlossary: handleAddGlossary,
    onDeleteGlossary: handleDeleteGlossary,
    onClearErrors: handleClearErrors,
    onClearAll: handleClearAll,
  };

  return (
    <ManageGlossaryState.Provider value={state}>
      <ManageGlossaryDispatch.Provider value={events}>{children}</ManageGlossaryDispatch.Provider>
    </ManageGlossaryState.Provider>
  );
};

const useManageGlossaryState = () => {
  const context = useContext(ManageGlossaryState);

  if (context === undefined) {
    throw new Error("useManageGlossaryState must be used within an ManageGlossaryProvider");
  }

  return context;
};

const useManageGlossaryDispatch = () => {
  const context = useContext(ManageGlossaryDispatch);

  if (context === undefined) {
    throw new Error("useManageGlossaryDispatch must be used within an ManageGlossaryProvider");
  }

  return context;
};

export { ManageGlossaryProvider, useManageGlossaryState, useManageGlossaryDispatch };
