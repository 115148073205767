import React, { createContext, useContext, useReducer } from "react";
import { GlossaryService } from "../services";

const GlossaryState = createContext();
const GlossaryDispatch = createContext();

const EVENT_TYPES = {
  UPDATE: "update",
  SUCCESS: "success",
  LOADING: "loading",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.LOADING]: (state, event) => {
    return {
      ...state,
      glossaryLoading: true,
      glossarySuccess: false,
      error: "",
    };
  },
  [EVENT_TYPES.SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      glossarySuccess: true,
      glossaryData: data,
      glossaryLoading: false,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      glossarySuccess: false,
      glossaryLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      glossarySuccess: false,
      glossaryLoading: false,
    };
  },
};

const INITIAL_STATE = {
  glossarySuccess: false,
  glossaryLoading: false,
  glossaryData: {},
  error: "",
};

const GlossaryReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const GlossaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlossaryReducer, INITIAL_STATE);

  const handleGetGlossary = () => {
    const { firstName, middleName, lastName, email } = state;
    dispatch({ type: EVENT_TYPES.LOADING });
    GlossaryService.getGlossary()
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.SUCCESS, payload: { data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetGlossary: handleGetGlossary,
    onClearErrors: handleClearErrors,
  };

  return (
    <GlossaryState.Provider value={state}>
      <GlossaryDispatch.Provider value={events}>{children}</GlossaryDispatch.Provider>
    </GlossaryState.Provider>
  );
};

const useGlossaryState = () => {
  const context = useContext(GlossaryState);

  if (context === undefined) {
    throw new Error("useGlossaryState must be used within an GlossaryProvider");
  }

  return context;
};

const useGlossaryDispatch = () => {
  const context = useContext(GlossaryDispatch);

  if (context === undefined) {
    throw new Error("useGlossaryDispatch must be used within an GlossaryProvider");
  }

  return context;
};

export { GlossaryProvider, useGlossaryState, useGlossaryDispatch };
