import React, { createContext, useContext, useReducer } from "react";
import PrintService from "../services/printService";
import AuthService from "../services/authService";
const PrintState = createContext();
const PrintDispatch = createContext();

const EVENT_TYPES = {
  PRINT_UPDATE: "print_update",
  PRINT_PREVIEW_UPDATE: "print_preview_update",
  PRINT_SELECT_ALL: "print_select_all",
  PRINT_CLEAR_ALL: "print_clear_all",
  PRINT_LOADING: "print_loading",
  PRINT_SUCCESS: "print_success",
  PRINT_CLEAR_ERRORS: "print_clear_errors",
  PRINT_ERROR: "print_error",
};

const EVENTS = {
  [EVENT_TYPES.PRINT_UPDATE]: (state, event) => {
    return {
      ...state,
      printItems: event.payload.itemsArray,
    };
  },
  [EVENT_TYPES.PRINT_PREVIEW_UPDATE]: (state, event) => {
    return {
      ...state,
      printPreviewEnabled: event.payload.printPreviewEnabled,
    };
  },
  [EVENT_TYPES.PRINT_SELECT_ALL]: (state, event) => {
    return {
      ...state,
      printSelectAll: event.payload.isSelectAll,
    };
  },
  [EVENT_TYPES.PRINT_CLEAR_ALL]: (state, event) => {
    return {
      ...state,
      printItems: [],
      printSelectAll: false,
      printPreviewEnabled: false,
      printSuccess: false,
      printLoading: false,
    };
  },
  [EVENT_TYPES.PRINT_LOADING]: (state) => {
    return {
      ...state,
      printLoading: true,
      printSuccess: false,
    };
  },
  [EVENT_TYPES.PRINT_SUCCESS]: (state, event) => {
    return {
      ...state,
      printSuccess: true,
      printLoading: false,
    };
  },
  [EVENT_TYPES.PRINT_ERROR]: (state, event) => {
    const { printError } = event.payload;
    return {
      ...state,
      printError,
      printSuccess: false,
      printLoading: false,
    };
  },
  [EVENT_TYPES.PRINT_CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      printError: "",
      printSuccess: false,
      printLoading: false,
    };
  },
};

const INITIAL_STATE = {
  defaultPrintItems: [
    {
      id: 0,
      label: "Select / Deselect All",
      value: "selectAll",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 1,
      label: "Active Compound",
      value: "activeCompound",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 2,
      label: "Estimated Indication",
      value: "estimatedIndication",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 3,
      label: "Company",
      value: "organisations",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 4,
      label: "Classification and Codes",
      value: "classificationAndCodes",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 5,
      label: "Administration Information",
      value: "administrationInformation",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 6,
      label: "Disease Information",
      value: "diseaseInformation",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 7,
      label: "Regulatory Information",
      value: "regulatoryInformation",
      isNested: false,
      isParent: true,
      parent: "",
      isChecked: true,
    },
    {
      id: 8,
      label: "European Union (EU)",
      value: "ema",
      isNested: true,
      isParent: false,
      parent: "regulatoryInformation",
      isChecked: true,
    },
    {
      id: 9,
      label: "U.S. Food and Drug Administration (FDA)",
      value: "fda",
      isNested: true,
      isParent: false,
      parent: "regulatoryInformation",
      isChecked: true,
    },
    {
      id: 10,
      label: "Clinical Trials",
      value: "clinicalTrials",
      isNested: false,
      isParent: true,
      parent: "",
      isChecked: true,
    },
    {
      id: 11,
      label: "E.U. Clinical Trial Registries",
      value: "euClinicalTrialRegistries",
      isNested: true,
      isParent: false,
      parent: "clinicalTrials",
      isChecked: true,
    },
    {
      id: 12,
      label: "U.S. Clinical Trial Registries",
      value: "usClinicalTrialRegistries",
      isNested: true,
      isParent: false,
      parent: "clinicalTrials",
      isChecked: true,
    },
    {
      id: 13,
      label: "Analysis",
      value: "analysis",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 14,
      label: "High Impact Reports (HIRs)",
      value: "highImpactReports",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 15,
      label: "References",
      value: "references",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 16,
      label: "Record Change Log",
      value: "recordChangeLog",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
  ],
  printItems: [
    {
      id: 0,
      label: "Select / Deselect All",
      value: "selectAll",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 1,
      label: "Active Compound",
      value: "activeCompound",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 2,
      label: "Estimated Indication",
      value: "estimatedIndication",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 3,
      label: "Company",
      value: "organisations",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 4,
      label: "Classification and Codes",
      value: "classificationAndCodes",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 5,
      label: "Administration Information",
      value: "administrationInformation",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 6,
      label: "Disease Information",
      value: "diseaseInformation",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 7,
      label: "Regulatory Information",
      value: "regulatoryInformation",
      isNested: false,
      isParent: true,
      parent: "",
      isChecked: true,
    },
    {
      id: 8,
      label: "European Union (EU)",
      value: "ema",
      isNested: true,
      isParent: false,
      parent: "regulatoryInformation",
      isChecked: true,
    },
    {
      id: 9,
      label: "U.S. Food and Drug Administration (FDA)",
      value: "fda",
      isNested: true,
      isParent: false,
      parent: "regulatoryInformation",
      isChecked: true,
    },
    {
      id: 10,
      label: "Clinical Trials",
      value: "clinicalTrials",
      isNested: false,
      isParent: true,
      parent: "",
      isChecked: true,
    },
    {
      id: 11,
      label: "E.U. Clinical Trial Registries",
      value: "euClinicalTrialRegistries",
      isNested: true,
      isParent: false,
      parent: "clinicalTrials",
      isChecked: true,
    },
    {
      id: 12,
      label: "U.S. Clinical Trial Registries",
      value: "usClinicalTrialRegistries",
      isNested: true,
      isParent: false,
      parent: "clinicalTrials",
      isChecked: true,
    },
    {
      id: 13,
      label: "Analysis",
      value: "analysis",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 14,
      label: "High Impact Reports (HIRs)",
      value: "highImpactReports",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 15,
      label: "References",
      value: "references",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
    {
      id: 16,
      label: "Record Change Log",
      value: "recordChangeLog",
      isNested: false,
      isParent: false,
      parent: "",
      isChecked: true,
    },
  ],
  printSelectAll: false,
  printPreviewEnabled: false,
  printError: "",
  printLoading: false,
  printSuccess: false,
};

const PrintReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const PrintProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PrintReducer, INITIAL_STATE);

  const handleUpdatePrintItems = (itemsArray) => {
    handleClearErrors();
    PrintService.setPrintItems(itemsArray);
    dispatch({ type: EVENT_TYPES.PRINT_UPDATE, payload: { itemsArray } });
  };
  const handleUpdatePrintPreview = (printPreviewEnabled) => {
    handleClearErrors();
    PrintService.setPrintPreviewStatus(printPreviewEnabled);
    dispatch({ type: EVENT_TYPES.PRINT_PREVIEW_UPDATE, payload: { printPreviewEnabled } });
  };
  const handlePrintSelectAll = (isSelectAll) => {
    dispatch({ type: EVENT_TYPES.PRINT_SELECT_ALL, payload: { isSelectAll: !isSelectAll } });
  };

  const handlePrintClearAll = (isSelectAll) => {
    PrintService._printItems([]);
    PrintService.setPrintPreviewStatus(false);
    dispatch({ type: EVENT_TYPES.PRINT_CLEAR_ALL });
  };

  // const handlePrint = (request) => {
  //   PrintService.print(request)
  //     .then((data) => {
  //       dispatch({ type: EVENT_TYPES.PRINT_SUCCESS, payload: { printData: data } });
  //       setTimeout(() => {
  //         handleClearAll();
  //       }, "2000");
  //       return Promise.resolve();
  //     })
  //     .catch(({ message }) => {
  //       dispatch({
  //         type: EVENT_TYPES.PRINT_ERROR,
  //         payload: {
  //           printError: message,
  //         },
  //       });
  //     });
  // };

  const handlePrintError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.PRINT_ERROR,
      payload: {
        printError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.PRINT_CLEAR_ERRORS });
  };

  const events = {
    onUpdatePrintItems: handleUpdatePrintItems,
    onUpdatePrintPreview: handleUpdatePrintPreview,
    onPrintSelectAll: handlePrintSelectAll,
    onPrintClearAll: handlePrintClearAll,
    onPrintError: handlePrintError,
    onClearErrors: handleClearErrors,
  };

  return (
    <PrintState.Provider value={state}>
      <PrintDispatch.Provider value={events}>{children}</PrintDispatch.Provider>
    </PrintState.Provider>
  );
};

const usePrintState = () => {
  const context = useContext(PrintState);

  if (context === undefined) {
    throw new Error("usePrintState must be used within an PrintProvider");
  }

  return context;
};

const usePrintDispatch = () => {
  const context = useContext(PrintDispatch);

  if (context === undefined) {
    throw new Error("usePrintDispatch must be used within an PrintProvider");
  }

  return context;
};

export { PrintProvider, usePrintState, usePrintDispatch };
