import { createTheme } from "@mui/material/styles";
import variables from "./App/scss/_appvariables.scss";
import { ubuntu } from "./App/jss/webfonts";

const themeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    // ***** KEEP HERE FOR REFERENCE *******
    // primary: {
    //   // light: will be calculated from palette.primary.main,
    //   main: '#ff4400',
    //   // dark: will be calculated from palette.primary.main,
    //   // contrastText: will be calculated to contrast with palette.primary.main
    // },
    // secondary: {
    //   light: '#0066ff',
    //   main: '#0044ff',
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#ffcc00',
    // },
    // // Used by `getContrastText()` to maximize the contrast between
    // // the background and the text.
    // contrastThreshold: 3,
    // // Used by the functions below to shift a color's luminance by approximately
    // // two indexes within its tonal palette.
    // // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
    secondary: {
      main: "#EF4720",
    },
    text: {
      primary: "#2c3657",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ubuntu.normal.medium,
    fontSize: 14,
    "h1, h2, h3, h4": {
      fontFamily: `${variables.displayprimaryfontfamily}, Arial, sans-serif`,
      color: "#fff",
    },
    h3: {
      fontWeight: "600",
    },
    h6: {
      fontSize: 16,
      fontWeight: "600",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "&.Mui-focused": {
            //outline: "5px auto -webkit-focus-ring-color",
            //outline: "1px dotted #666",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontWeight: 600,
          lineHeight: 1.8,
        },
      },
    },
  },
};

export const Theme = createTheme({
  ...themeOptions,
});
