import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";

const SaveSearchService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  async saveSearch(requestObj) {
    let token = localStorage.getItem("token");
    try {
      let response = await fetchWithTimeout(`${this._url}/search/savesearch`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async getSavedSearchById(id) {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(`${this._url}/search/getsavedsearch?uniqueId=${id}`, {
          method: "GET",
          headers: {
            "content-type": "application/vnd.restful+json",
            authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },
  async getSavedSearches() {
    let token = localStorage.getItem("token");
    let requestObj = {
      from: 0,
      size: 10000,
    };
    try {
      let response = await fetchWithTimeout(`${this._url}/search/getsavedsearches`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async deleteSavedSearch(id) {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(`${this._url}/search/deletesavedsearch?uniqueId=${id}`, {
          method: "GET",
          headers: {
            "content-type": "application/vnd.restful+json",
            authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },
};

export default SaveSearchService;
