import axios from "axios";
import { saveAs } from "file-saver";
import { fetchWithTimeout } from "./serviceHelpers";
import AuthService from "./authService";

const PrintService = {
  _printItems(itemsArray) {
    if (typeof itemsArray !== "undefined") {
      localStorage.setItem("printItems", JSON.stringify(itemsArray));
      return;
    }
    return localStorage.getItem("printItems");
  },
  _printPreviewStatus(boolValue) {
    if (typeof boolValue !== "undefined") {
      localStorage.setItem("printPreviewEnabled", JSON.stringify(boolValue));
      return;
    }
    return localStorage.getItem("printPreviewEnabled");
  },
  setPrintPreviewStatus(boolValue) {
    this._printPreviewStatus(boolValue);
  },
  getPrintPreviewStatus() {
    return JSON.stringify(this._printPreviewStatus());
  },
  setPrintItems(printItems) {
    this._printPreviewStatus(printItems);
  },
  getPrintItems(printItems) {
    return JSON.stringify(this._printItems());
  },
  clearPrintItems() {
    this._printItems([]);
  },
};

export default PrintService;
