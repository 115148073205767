import { useAuthState } from "../../providers/authProvider";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import LogoReversedImg from "../../App/images/ihsi-logo-reversed.svg";
import ButtonRounded from "../ButtonRounded";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ThemeDark } from "../../themeDark";
import styles from "./footer.module.scss";

const useStyles = makeStyles({
  form: {
    "&::focus-visible": {
      outline: "none !important",
    },
  },
  iconButton: {
    "&:focus": {
      outline: "1px dotted rgba(255,255,255,0.5)",
    },
  },
});

const Footer = (props) => {
  const classes = useStyles(props);
  const { isLoggedIn, currentUser } = useAuthState();

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className={`col-xs col-lg-4 ${styles.footerCol}`}>
            <img className={styles.footerLogo} src={LogoReversedImg} width="200" height="70" />
            <div className={styles.footerNav}>
              <ul className={`${styles.navList} ${styles.col1}`}>
                <li className={styles.navItem}>
                  <Link className={styles.footerLink} to="/" title="IHSI Homepage">
                    Home
                  </Link>
                </li>
                <li className={styles.navItem}>
                  <Link className={styles.footerLink} to="/about" title="About Us">
                    About
                  </Link>
                </li>
                <li className={styles.navItem}>
                  <Link className={styles.footerLink} to="/contact" title="Contact Us">
                    Contact
                  </Link>
                </li>
                {isLoggedIn && (
                  <div>
                    <li className={styles.navItem}>
                      <Link className={styles.footerLink} to="/faq" title="FAQs">
                        FAQs
                      </Link>
                    </li>
                    <li className={styles.navItem}>
                      <Link className={styles.footerLink} to="/glossary" title="Glossary">
                        Glossary
                      </Link>
                    </li>
                  </div>
                )}
              </ul>
              <ul className={`${styles.navList} ${styles.col2}`}></ul>
            </div>
          </div>
          <div className={`col-xs col-lg-4 ${styles.footerCol}`}>
            <h4 className={styles.heading}>Want to know more?</h4>
            <p>
              To learn more about IHSI&apos;s work and how to become a member, please visit{" "}
              <a
                className={`${styles.footerLink} ${styles.underlined}`}
                href="http://www.ihsi-health.org"
                title="IHSI Health"
                target="_blank"
                rel="noreferrer"
              >
                www.ihsi-health.org
              </a>
              .
            </p>
          </div>
          <div className={`col-xs col-lg-4 ${styles.footerCol}`}>
            <p>
              To participate as a Medical Reviewer and provide your input on in-development pharmaceuticals, please fill
              out this{" "}
              <a
                className={`${styles.footerLink} ${styles.underlined}`}
                href="https://survey.ecri.org/surveys/IHSI-Stakeholder-Recruitment-Form?site=website"
                title="IHSI Stakeholder Recruitment Form"
                target="_blank"
                rel="noreferrer"
              >
                form
              </a>
              .
            </p>
            <p>
              <i>Medical Reviewers should be practicing medical doctors w/+5 years of experience.</i>
            </p>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className="row">
            <div className="col-xs-12">
              <p>Copyright &copy; {new Date().getFullYear()} | IHSI | All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
