import axios from "axios";
import AuthService from "./authService";
import { fetchWithTimeout } from "./serviceHelpers";

const AccountSettingsService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  _accountSettingsData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("accountSettingsData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("accountSettingsData");
  },

  async getAccountSettings() {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(`${this._url}/accountsettings`, {
          method: "GET",
          headers: {
            "content-type": "application/vnd.restful+json",
            authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        this._accountSettingsData(data);
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },

  async updateUserProfile(requestObj) {
    // let defaultRequestObj = {
    //   firstName: "string",
    //   middleName: "string",
    //   lastName: "string",
    //   email: "string",,
    // };

    try {
      let response = await fetchWithTimeout(`${this._url}/accountsettings/updateuserprofile`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      let currentUser = JSON.parse(localStorage.getItem("user"));
      currentUser.firstName = data.originalRequest.firstName;
      currentUser.middleName = data.originalRequest.middleName;
      currentUser.lastName = data.originalRequest.lastName;
      currentUser.email = data.originalRequest.email;
      localStorage.setItem("user", JSON.stringify(currentUser));
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async setNotificationEnrollment(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/enrollment/enrollnotification`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      if (data.responseCode === "ENROLL_NOTIFICATION_SUCCESS") {
        let settingsStatus = await this.getAccountSettings();
        return settingsStatus;
      } else {
        throw new Error("Enrollment was not successful");
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  getAccountSettingsData() {
    return JSON.parse(localStorage.getItem("accountSettingsData"));
  },
};

export default AccountSettingsService;
