import React, { createContext, useContext, useReducer } from "react";
import { FaqService } from "../services";

const FaqState = createContext();
const FaqDispatch = createContext();

const EVENT_TYPES = {
  GET_FAQ_SUCCESS: "get_faq_success",
  GET_FAQ_LOADING: "get_faq_loading",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.GET_FAQ_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getFaqSuccess: true,
      getFaqLoading: false,
      faqData: data,
    };
  },
  [EVENT_TYPES.GET_FAQ_LOADING]: (state, event) => {
    return {
      ...state,
      getFaqSuccess: false,
      getFaqLoading: true,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      faqSuccess: false,
      getFaqLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      faqSuccess: false,
    };
  },
};

const INITIAL_STATE = {
  error: "",
  getFaqSuccess: false,
  getFaqLoading: false,
  faqData: {},
};

const FaqReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const FaqProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FaqReducer, INITIAL_STATE);

  const handleGetFaqs = (req) => {
    dispatch({ type: EVENT_TYPES.GET_FAQ_LOADING });
    FaqService.getFaqs(req)
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.GET_FAQ_SUCCESS, payload: { data: data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetFaqs: handleGetFaqs,
    onClearErrors: handleClearErrors,
  };

  return (
    <FaqState.Provider value={state}>
      <FaqDispatch.Provider value={events}>{children}</FaqDispatch.Provider>
    </FaqState.Provider>
  );
};

const useFaqState = () => {
  const context = useContext(FaqState);

  if (context === undefined) {
    throw new Error("useFaqState must be used within an FaqProvider");
  }

  return context;
};

const useFaqDispatch = () => {
  const context = useContext(FaqDispatch);

  if (context === undefined) {
    throw new Error("useFaqDispatch must be used within an FaqProvider");
  }

  return context;
};

export { FaqProvider, useFaqState, useFaqDispatch };
