import React, { useEffect, useState } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAuthState } from "../../providers/authProvider";

function PrivateRoute({ children, redirectTo, allowedRole = "" }) {
  // const [user, setUser] = useState("");

  const { isLoggedIn, currentUser } = useAuthState();
  const location = useLocation();
  if (isLoggedIn) {
    if (currentUser === null) {
      return (
        <div className="row center-xs middle-xs">
          <div className="col-xs-12">
            <CircularProgress sx={{ position: "absolute", top: "50%", marginTop: "-1rem" }} />
          </div>
        </div>
      );
    } else {
      if (allowedRole) {
        // let matchedRoleIndex = currentUser.roles.findIndex((element) => element.includes(allowedRole));
        let matchedRoleIndex = -1;
        let matchFound = false;
        if (Array.isArray(allowedRole)) {
          matchedRoleIndex = allowedRole.filter((item) => currentUser.roles.includes(item));
          if (matchedRoleIndex.length > 0) {
            return children;
          }
        } else {
          matchedRoleIndex = (currentUser.roles || []).findIndex((element) => element.includes(allowedRole));
          if (allowedRole === currentUser.roles[matchedRoleIndex]) {
            return children;
          }
        }

        return (
          <Navigate
            to={redirectTo ? redirectTo : "/notallowed"}
            state={{ from: location, isMembersOnly: true, allowedRole: allowedRole }}
            replace={true}
          />
        );
      }
      return children;
    }
  }
  return (
    <Navigate
      to={redirectTo ? redirectTo : "/login"}
      state={{
        from: location,
        isMembersOnly: true,
        allowedRole: allowedRole,
        redirectUrl: `${location.pathname}${location.search ? location.search : ""}`,
      }}
      replace={true}
    />
  );
}

export default PrivateRoute;
