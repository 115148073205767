import Ubuntu300LatinExtWoff2 from "../scss/webfonts/Ubuntu-300-Latin-Ext.woff2";
import Ubuntu400LatinExtWoff2 from "../scss/webfonts/Ubuntu-400-Latin-Ext.woff2";
import Ubuntu500LatinExtWoff2 from "../scss/webfonts/Ubuntu-500-Latin-Ext.woff2";
import Ubuntu700LatinExtWoff2 from "../scss/webfonts/Ubuntu-700-Latin-Ext.woff2";
import UbuntuItalic300LatinExtWoff2 from "../scss/webfonts/Ubuntu-Italic-300-Latin-Ext.woff2";
import UbuntuItalic400LatinExtWoff2 from "../scss/webfonts/Ubuntu-Italic-400-Latin-Ext.woff2";
import UbuntuItalic500LatinExtWoff2 from "../scss/webfonts/Ubuntu-Italic-500-Latin-Ext.woff2";
import UbuntuItalic700LatinExtWoff2 from "../scss/webfonts/Ubuntu-Italic-700-Latin-Ext.woff2";

export const ubuntu = {
  normal: {
    light: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 300,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-300-latin-ext'),
        url(${Ubuntu300LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    medium: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 400,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-400-latin-ext'),
        url(${Ubuntu400LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    semibold: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-500-latin-ext'),
        url(${Ubuntu500LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    bold: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 700,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-700-latin-ext'),
        url(${Ubuntu700LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
  },
  italic: {
    light: {
      fontFamily: "Ubuntu",
      fontStyle: "italic",
      fontWeight: 300,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-italic-300-latin-ext'),
        url(${UbuntuItalic300LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    medium: {
      fontFamily: "Ubuntu",
      fontStyle: "italic",
      fontWeight: 400,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-italic-400-latin-ext'),
        url(${UbuntuItalic400LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    semibold: {
      fontFamily: "Ubuntu",
      fontStyle: "italic",
      fontWeight: 500,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-italic-500-latin-ext'),
        url(${UbuntuItalic500LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
    bold: {
      fontFamily: "Ubuntu",
      fontStyle: "italic",
      fontWeight: 700,
      fontDisplay: "swap",
      src: `
        local('Ubuntu'),
        local('ubuntu-italic-700-latin-ext'),
        url(${UbuntuItalic700LatinExtWoff2}) format('woff2')
      `,
      unicodeRange:
        "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
    },
  },
};
