import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ThemeProvider } from "@mui/material/styles";
import { Theme } from "../../theme";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessImg from "../../App/images/success-check.svg";
import { useContactState, useContactDispatch, ContactProvider } from "../../providers/contactProvider";
import styles from "./contactForm.module.scss";

const ContactForm = (props, history) => {
  const form = useRef();
  const submitBtn = useRef();
  const { onContact, onUpdate } = useContactDispatch();
  const { error, formEmail, formName, formMessage, contactSuccess } = useContactState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    message: yup.string().required("A message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, props) => {
      setMessage("");
      setLoading(true);
      onContact();
    },
  });

  return (
    <ThemeProvider theme={Theme}>
      <div data-theme="light" className={`${styles.contactSection}`}>
        {!contactSuccess && (
          <form className={styles.contactForm}>
            <div className={`row ${styles.formRow}`}>
              <div className={"col-xs"}>
                <TextField
                  sx={{ height: "4.3rem" }}
                  label="Name"
                  id="name"
                  name="name"
                  fullWidth
                  size="small"
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onUpdate(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ""}
                />
                <TextField
                  sx={{ height: "4.3rem" }}
                  label="Email"
                  id="email"
                  name="email"
                  fullWidth
                  size="small"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onUpdate(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ""}
                />
                <TextField
                  sx={{ height: "11.3rem" }}
                  label="Message"
                  id="message"
                  name="message"
                  fullWidth
                  size="small"
                  value={formik.values.message}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onUpdate(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message ? formik.errors.message : ""}
                  multiline
                  rows={6}
                />
              </div>
            </div>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {loading && (
                <div className={styles.loadingInline}>
                  <CircularProgress style={{ width: "1.5rem", height: "1.5rem", verticalAlign: "middle" }} />
                </div>
              )}
              <Button variant="contained" type="submit" color="secondary" onClick={formik.handleSubmit} ref={submitBtn}>
                Send Message
              </Button>
            </Stack>
          </form>
        )}
        {contactSuccess && (
          <div className={styles.contactSuccess}>
            <div className={"row center-xs"}>
              <div className="col-xs-12 middle-xs">
                <img className={styles.successImg} src={SuccessImg} alt="Open Email Image" />
                <Typography sx={{ marginBottom: "2rem" }} variant="h4">
                  Message was successful
                </Typography>
                <Typography sx={{ marginBottom: "2rem", textAlign: "center" }} variant="p">
                  Your message has been successfully sent to IHSI. Someone should respond within 24-48hrs of your
                  request.
                </Typography>
              </div>
            </div>
          </div>
        )}

        <div className="row center-xs">
          <div className="col-xs-12 col-md-6 col-lg-5">
            <div className={`${styles.alertBox} ${styles.error} ${error ? styles.visible : ""}`}>
              <Typography variant="p">{`Error: ${error}`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ContactForm;
