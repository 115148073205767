import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ExportModal from "../ExportModal";
import { useExportState, useExportDispatch } from "../../providers/exportProvider";
import { useAuthState, useAuthDispatch } from "../../providers/authProvider";

import styles from "./exportStatusBar.module.scss";

const css = {
  row: {
    alignItems: "center",
    justifyContent: "center",
  },
  resultsHeading: {
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  buttonPrimary: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  buttonSecondary: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#f7fbff",
    },
  },
};
const ExportStatusBar = (props) => {
  const { exportItemCount } = props;
  const { exportItems, exportSelectAll, exportSuccess } = useExportState();
  const { searchData } = useAuthState();
  const { onExport, onClearAll } = useExportDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isPanelHidden, setIsPanelHidden] = useState(false);
  const [exportType, setExportType] = useState("csv");

  const handleModalOpen = (target) => {
    if (!isOpen) {
      setIsOpen(true);
      setIsPanelHidden(true);
    }
  };

  const handleModalClose = () => {
    if (isOpen) {
      setIsOpen(false);
      setIsPanelHidden(false);
    }
  };

  const handleExportType = (event) => {
    setExportType(event.target.value);
  };

  const handleClearAll = () => {
    onClearAll();
    handleModalClose();
  };

  const onSubmit = (e) => {
    onClearAll();
    handleModalClose();
    let request = {};
    let includeAllFieldsStatus = false;
    if (exportType === "xlsx") {
      includeAllFieldsStatus = true;
    }
    if (exportSelectAll) {
      request = {
        ...searchData.originalRequest,
        exportAll: exportSelectAll,
        exportFormat: exportType,
        includeAllFields: includeAllFieldsStatus,
      };
    } else {
      request = {
        hssIdsToExport: exportItems,
        exportAll: exportSelectAll,
        exportFormat: exportType,
        includeAllFields: includeAllFieldsStatus,
      };
    }

    onExport(request);
  };

  return (
    <>
      <ExportModal
        open={isOpen}
        handleClose={handleModalClose}
        handleExportType={handleExportType}
        exportType={exportType}
        itemCount={exportItemCount}
        handleSubmit={onSubmit}
        handleClearAll={handleClearAll}
        exportSuccess={exportSuccess}
      />
      <div className={`${styles.exportStatusBar} ${isOpen ? styles.hide : ""}`}>
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12">
              <Stack sx={css.row} direction="row" spacing={2} align="center">
                <div className={styles.resultsBox}>
                  <Typography sx={css.resultsHeading} variant="h4">
                    Selected {exportItemCount} result{exportItemCount > 1 ? "s" : ""}
                  </Typography>
                </div>
                <Button sx={css.buttonPrimary} variant="contained" onClick={() => handleModalOpen("Export")}>
                  Export
                </Button>
                <Button sx={css.buttonSecondary} variant="outlined" color="primary" onClick={() => onClearAll()}>
                  Clear All
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportStatusBar;
