import axios from "axios";
import AuthService from "./authService";
import { fetchWithTimeout } from "./serviceHelpers";

const NotificationService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  _notificationData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("notificationData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("notificationData");
  },

  async getNotifications(size = 10000, from = 0) {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(`${this._url}/recentlyupdatedrecords?Size=${size}&From=${from}`, {
          method: "GET",
          headers: {
            "content-type": "application/vnd.restful+json",
            authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        //this._notificationData(data);
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },
  async toggleNotifyPopup() {
    let token = localStorage.getItem("token");
    try {
      let response = await fetchWithTimeout(`${this._url}/enrollment/disableemailnotificationpopup`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async adminNotificationToggle(userId, isEnabled) {
    let token = localStorage.getItem("token");
    try {
      let response = await fetchWithTimeout(
        `${this._url}/auth/turnonoroffnotification?userId=${userId}&enablenotification=${isEnabled}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  getNotificationData() {
    return JSON.parse(localStorage.getItem("notificationData"));
  },
};

export default NotificationService;
