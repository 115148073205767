import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { useExportState, useExportDispatch } from "../../providers/exportProvider";
import styles from "./exportModal.module.scss";

const css = {
  containedButton: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "20rem",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ExportModal = (props) => {
  const { handleClose, open, itemCount, handleExportType, exportType, handleSubmit, handleClearAll, exportSuccess } =
    props;
  const { exportLoading, exportFile, exportError } = useExportState();
  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Export
          {/* Export - {itemCount} item{`${itemCount > 0 ? "s" : ""}`} selected */}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ minWidth: 120 }}>
            <div className={styles.exportDisclaimer}>
              The ability to export HSS Records is under development, currently a{" "}
              <HashLink className={styles.disclaimerLink} to="/faq#EXPORTINGDATA">
                limited
              </HashLink>{" "}
              number of fields will be exported for the selected record(s).
            </div>
            {!exportSuccess && (
              <>
                <Typography sx={{ marginBottom: "1rem", display: "block" }} variant="p">
                  Please choose your export format:
                </Typography>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-helper-label">Please Select...</InputLabel>
                  <Select
                    value={exportType}
                    onChange={handleExportType}
                    label="Please select..."
                    // inputProps={{ "aria-label": "Select Filter" }}
                  >
                    <MenuItem value="csv">CSV</MenuItem>
                    <MenuItem value="xml">XML</MenuItem>
                    <MenuItem value="xlsx">XLSX</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
            {exportSuccess && (
              <div className={StyleSheet.successBox}>
                <Typography sx={{ marginBottom: "1rem", display: "block" }} variant="p">
                  Export Successful. Please download your file here.
                </Typography>
                <Button sx={css.containedButton} autoFocus variant="contained">
                  Download
                </Button>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={css.containedButton} autoFocus variant="contained" onClick={handleSubmit}>
            Export
          </Button>
          <Button onClick={handleClearAll}>Cancel</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ExportModal;
