import React, { createContext, useContext, useReducer } from "react";
import { AccountSettingsService } from "../services";
import { saveAs } from "file-saver";

const AccountSettingsState = createContext();
const AccountSettingsDispatch = createContext();

const EVENT_TYPES = {
  GET_ACCOUNT_SETTINGS_LOADING: "get_account_settings_loading",
  GET_ACCOUNT_SETTINGS_SUCCESS: "get_account_settings_success",
  UPDATE_USER_PROFILE_LOADING: "update_user_profile_loading",
  UPDATE_USER_PROFILE_SUCCESS: "update_user_profile_success",
  SET_NOTIFICATION_ENROLLMENT_LOADING: "set_notification_enrollment_loading",
  SET_NOTIFICATION_ENROLLMENT_SUCCESS: "set_notification_enrollment_success",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.SET_NOTIFICATION_ENROLLMENT_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      notificationEnrollmentSuccess: true,
      notificationEnrollmentLoading: false,
      accountSettingsData: data,
    };
  },
  [EVENT_TYPES.SET_NOTIFICATION_ENROLLMENT_LOADING]: (state, event) => {
    return {
      ...state,
      notificationEnrollmentSuccess: false,
      notificationEnrollmentLoading: true,
    };
  },
  [EVENT_TYPES.GET_ACCOUNT_SETTINGS_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getAccountSettingsSuccess: true,
      getAccountSettingsLoading: false,
      accountSettingsData: data,
    };
  },
  [EVENT_TYPES.GET_ACCOUNT_SETTINGS_LOADING]: (state, event) => {
    return {
      ...state,
      getAccountSettingsSuccess: false,
      getAccountSettingsLoading: true,
    };
  },
  [EVENT_TYPES.UPDATE_USER_PROFILE_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      updateUserProfileSuccess: true,
      updateUserProfileLoading: false,
      updateUserProfileData: data,
    };
  },
  [EVENT_TYPES.UPDATE_USER_PROFILE_LOADING]: (state, event) => {
    return {
      ...state,
      updateUserProfileSuccess: false,
      updateUserProfileLoading: true,
    };
  },
  [EVENT_TYPES.UPDATE_USER_PROFILE_ERROR]: (state, event) => {
    const { updateUserProfileError } = event.payload;
    return {
      ...state,
      updateUserProfileError,
      updateUserProfileSuccess: false,
      updateUserProfileLoading: false,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { getAccountSettingsError } = event.payload;
    return {
      ...state,
      getAccountSettingsError,
      getAccountSettingsSuccess: false,
      getAccountSettingsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      getAccountSettingsError: "",
      getAccountSettingsSuccess: false,
      getAccountSettingsLoading: false,
      notificationEnrollmentSuccess: false,
      notificationEnrollmentLoading: false,
      notificationEnrollmentError: "",
    };
  },
};

const INITIAL_STATE = {
  getAccountSettingsError: "",
  getAccountSettingsSuccess: false,
  getAccountSettingsLoading: false,
  updateUserProfileError: "",
  updateUserProfileSuccess: false,
  updateUserProfileLoading: false,
  updateUserProfileData: {},
  accountSettingsData: {},
  notificationEnrollmentSuccess: false,
  notificationEnrollmentLoading: false,
  notificationEnrollmentError: "",
};

const HirReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const AccountSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HirReducer, INITIAL_STATE);

  const handleGetAccountSettings = () => {
    dispatch({ type: EVENT_TYPES.GET_ACCOUNT_SETTINGS_LOADING });
    AccountSettingsService.getAccountSettings()
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_ACCOUNT_SETTINGS_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            getAccountSettingsError: message,
          },
        });
      });
  };
  const handleUpdateUserProfile = (req) => {
    dispatch({ type: EVENT_TYPES.UPDATE_USER_PROFILE_LOADING });
    AccountSettingsService.updateUserProfile(req)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.UPDATE_USER_PROFILE_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.UPDATE_USER_PROFILE_ERROR,
          payload: {
            updateUserProfileError: message,
          },
        });
      });
  };
  const handleSetNotificationEnrollment = (requestObj) => {
    dispatch({ type: EVENT_TYPES.SET_NOTIFICATION_ENROLLMENT_LOADING });
    AccountSettingsService.setNotificationEnrollment(requestObj)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.SET_NOTIFICATION_ENROLLMENT_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            notificationEnrollmentError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        getAccountSettingsError: errorMsg,
      },
    });
  };

  const handleClearSettingsErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetAccountSettings: handleGetAccountSettings,
    onUpdateUserProfile: handleUpdateUserProfile,
    onSetNotificationEnrollment: handleSetNotificationEnrollment,
    onClearSettingsErrors: handleClearSettingsErrors,
  };

  return (
    <AccountSettingsState.Provider value={state}>
      <AccountSettingsDispatch.Provider value={events}>{children}</AccountSettingsDispatch.Provider>
    </AccountSettingsState.Provider>
  );
};

const useAccountSettingsState = () => {
  const context = useContext(AccountSettingsState);

  if (context === undefined) {
    throw new Error("useAccountSettingsState must be used within an AccountSettingsProvider");
  }

  return context;
};

const useAccountSettingsDispatch = () => {
  const context = useContext(AccountSettingsDispatch);

  if (context === undefined) {
    throw new Error("useAccountSettingsDispatch must be used within an AccountSettingsProvider");
  }

  return context;
};

export { AccountSettingsProvider, useAccountSettingsState, useAccountSettingsDispatch };
