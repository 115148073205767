import { createTheme } from "@mui/material/styles";
import variables from "./App/scss/_appvariables.scss";
import { ubuntu } from "./App/jss/webfonts";

const themeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#EF4720",
    },
    secondary: {
      main: "#00C1FF",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ubuntu.normal.medium,
    fontSize: 14,
    "h1, h2, h3, h4, h5, h6, p": {
      fontFamily: `${variables.displayprimaryfontfamily}, Arial, sans-serif`,
      color: "#fff",
    },
    p: {
      color: "#fff",
      display: "block",
    },
    h3: {
      fontWeight: "600",
      color: "#FFF",
    },
    h4: {
      fontWeight: "600",
      color: "#FFF",
    },
    h5: {
      color: "#FFF",
    },
    h6: {
      color: "#FFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&.Mui-focused": {
            //outline: "5px auto -webkit-focus-ring-color",
            outline: "1px dotted #fff",
          },
          "&:hover": {
            color: "#FFF",
            textDecoration: "none",
          },
          "&:active": {
            color: "#FFF",
            textDecoration: "none",
          },
          "&:focus": {
            color: "#FFF",
            textDecoration: "none",
          },
        },
        outlinedPrimary: {
          borderWidth: "2px",
          opacity: "1",
          borderColor: "#EF4720",
          "&:hover": {
            borderWidth: "2px",
          },
        },
      },
    },
    MukButtonBase: {
      styleOverrides: {
        root: {},
        outlined: {
          // borderWidth: "5px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: "#3A465B",
          border: "1px solid #3A465B",
          "&.Mui-focused": {
            //outline: "5px auto -webkit-focus-ring-color",
            outline: "1px dotted #fff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          MuiFocused: {
            borderWidth: "1px",
          },
        },
        notchedOutline: {
          borderWidth: "0",
          border: "none !important",
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            marginLeft: "-.26rem",
            color: "#FFF",
          },
          backgroundColor: "#3A465B",
          padding: "0 .4rem",
          borderRadius: "4px",
          marginLeft: "-.26rem",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          opacity: "0.6",
        },
      },
    },
  },
};

export const ThemeDark = createTheme({
  ...themeOptions,
});
