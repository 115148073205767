import React, { createContext, useContext, useReducer } from "react";
import { ManageUsersService } from "../services";
import { saveAs } from "file-saver";

const ManageUsersState = createContext();
const ManageUsersDispatch = createContext();

const EVENT_TYPES = {
  DELETE_USER_TOGGLE_LOADING: "delete_user_toggle_loading",
  DELETE_USER_TOGGLE_SUCCESS: "delete_user_toggle_success",
  SUSPEND_TOGGLE_USER_LOADING: "suspend_toggle_user_loading",
  SUSPEND_TOGGLE_USER_SUCCESS: "suspend_toggle_user_success",
  ASSIGN_USER_ROLES_LOADING: "assign_user_roles_loading",
  ASSIGN_USER_ROLES_SUCCESS: "assign_user_roles_success",
  UPDATE_CACHED_ROLES: "update_cached_roles",
  GET_USERS_LIST_LOADING: "get_users_list_loading",
  GET_USERS_LIST_SUCCESS: "get_users_list_success",
  GET_IHSI_USERS_LIST_LOADING: "get_ihsi_users_list_loading",
  GET_IHSI_USERS_LIST_SUCCESS: "get_ihsi_users_list_success",
  CLEAR_MANAGE_USERS_ERRORS: "clear_manage_users_errors",
  MANAGE_USERS_ERROR: "manage_users_error",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE_CACHED_ROLES]: (state, event) => {
    const { roles } = event.payload;
    return {
      ...state,
      cachedRoles: roles,
    };
  },
  [EVENT_TYPES.DELETE_USER_TOGGLE_LOADING]: (state, event) => {
    return {
      ...state,
      deleteUserToggleLoading: true,
      deleteUserToggleSuccess: false,
    };
  },
  [EVENT_TYPES.DELETE_USER_TOGGLE_SUCCESS]: (state, event) => {
    return {
      ...state,
      deleteUserToggleLoading: false,
      deleteUserToggleSuccess: true,
    };
  },
  [EVENT_TYPES.SUSPEND_TOGGLE_USER_LOADING]: (state, event) => {
    return {
      ...state,
      suspendToggleUserLoading: true,
      suspendToggleUserSuccess: false,
    };
  },
  [EVENT_TYPES.SUSPEND_TOGGLE_USER_SUCCESS]: (state, event) => {
    return {
      ...state,
      suspendToggleUserLoading: false,
      suspendToggleUserSuccess: true,
    };
  },
  [EVENT_TYPES.ASSIGN_USER_ROLES_LOADING]: (state, event) => {
    return {
      ...state,
      assignUserRolesLoading: true,
      assignUserRolesSuccess: false,
    };
  },
  [EVENT_TYPES.ASSIGN_USER_ROLES_SUCCESS]: (state, event) => {
    return {
      ...state,
      assignUserRolesLoading: false,
      assignUserRolesSuccess: true,
    };
  },
  [EVENT_TYPES.GET_USERS_LIST_LOADING]: (state, event) => {
    return {
      ...state,
      getUsersListLoading: true,
      getUsersListSuccess: false,
    };
  },
  [EVENT_TYPES.GET_USERS_LIST_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getUsersListLoading: false,
      getUsersListSuccess: true,
      usersList: data,
    };
  },
  [EVENT_TYPES.GET_IHSI_USERS_LIST_LOADING]: (state, event) => {
    return {
      ...state,
      getIhsiUsersListLoading: true,
      getIhsiUsersListSuccess: false,
    };
  },
  [EVENT_TYPES.GET_IHSI_USERS_LIST_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getIhsiUsersListLoading: false,
      getIhsiUsersListSuccess: true,
      ihsiUsersList: data,
    };
  },
  [EVENT_TYPES.MANAGE_USERS_ERROR]: (state, event) => {
    const { manageUsersError } = event.payload;
    return {
      ...state,
      manageUsersError,
      manageUsersSuccess: false,
      manageUsersLoading: false,
      getUsersListLoading: false,
      getUsersListSuccess: false,
      getIhsiUsersListLoading: false,
      getIhsiUsersListSuccess: false,
      assignUserRolesLoading: false,
      assignUserRolesSuccess: false,
      suspendToggleUserLoading: false,
      suspendToggleUserSuccess: false,
      deleteUserToggleLoading: false,
      deleteUserToggleSuccess: false,
      cachedRoles: [],
    };
  },
  [EVENT_TYPES.CLEAR_MANAGE_USERS_ERRORS]: (state) => {
    return {
      ...state,
      manageUsersError: "",
      manageUsersSuccess: false,
      manageUsersLoading: false,
      assignUserRolesLoading: false,
      assignUserRolesSuccess: false,
      getUsersListLoading: false,
      getUsersListSuccess: false,
      getIhsiUsersListLoading: false,
      getIhsiUsersListSuccess: false,
      suspendToggleUserLoading: false,
      suspendToggleUserSuccess: false,
      deleteUserToggleLoading: false,
      deleteUserToggleSuccess: false,
      cachedRoles: [],
    };
  },
};

let defaultUserListRequest = {
  size: 10000,
  from: 0,
  orderBy: "first_name", // user_id, first_name, last_name, email, created_date, last_login_date, last_login_week, last_login_month, suspended, deleted
  sortOrder: "desc", // asc, desc
  // deleted: null, // true, false
  // suspended: null, // true, false
  // lastLoginMonth: 0,
  // lastLoginWeek: 0,
};
let defaultIhsiUserListRequest = {
  size: 10000,
  from: 0,
  orderBy: "first_name", // user_id, first_name, last_name, email, created_date, last_login_date, last_login_week, last_login_month, suspended, deleted
  sortOrder: "desc", // asc, desc
  // deleted: null, // true, false
  // suspended: null, // true, false
  // lastLoginMonth: 0,
  // lastLoginWeek: 0,
};
const INITIAL_STATE = {
  manageUsersError: "",
  manageUsersSuccess: false,
  manageUsersLoading: false,
  getUsersListLoading: false,
  getUsersListSuccess: false,
  getIhsiUsersListLoading: false,
  getIhsiUsersListSuccess: false,
  assignUserRolesLoading: false,
  assignUserRolesSuccess: false,
  suspendToggleUserLoading: false,
  suspendToggleUserSuccess: false,
  deleteUserToggleLoading: false,
  deleteUserToggleSuccess: false,
  usersList: {},
  ihsiUSersList: {},
  defaultUserListRequest: defaultUserListRequest,
  defaultIhsiUserListRequest: defaultIhsiUserListRequest,

  cachedRoles: [],
};

const ManageUsersReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ManageUsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManageUsersReducer, INITIAL_STATE);

  const handleUpdateCachedRoles = (roles) => {
    //handleClearManageUsersErrors();
    dispatch({ type: EVENT_TYPES.UPDATE_CACHED_ROLES, payload: { roles } });
  };

  const handleGetUsersList = (req) => {
    dispatch({ type: EVENT_TYPES.GET_USERS_LIST_LOADING });
    ManageUsersService.getUsersList(req)
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.GET_USERS_LIST_SUCCESS, payload: { data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_USERS_ERROR,
          payload: {
            manageUsersError: message,
          },
        });
      });
  };
  const handleGetIhsiUsersList = (req) => {
    dispatch({ type: EVENT_TYPES.GET_IHSI_USERS_LIST_LOADING });
    ManageUsersService.getIhsiUsersList(req)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_IHSI_USERS_LIST_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_USERS_ERROR,
          payload: {
            manageUsersError: message,
          },
        });
      });
  };
  const handleAssignUserRoles = (userId, roleId) => {
    dispatch({ type: EVENT_TYPES.ASSIGN_USER_ROLES_LOADING });
    ManageUsersService.assignUserRoles(userId, roleId)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.ASSIGN_USER_ROLES_SUCCESS });
        // setTimeout(() => {
        //   dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_USERS_ERRORS });
        // }, 6000);
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_USERS_ERROR,
          payload: {
            manageUsersError: message,
          },
        });
      });
  };

  const handleSuspendOrActivateUser = (userId) => {
    dispatch({ type: EVENT_TYPES.SUSPEND_TOGGLE_USER_LOADING });
    ManageUsersService.suspendOrActivateUser(userId)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.SUSPEND_TOGGLE_USER_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_USERS_ERROR,
          payload: {
            manageUsersError: message,
          },
        });
      });
  };
  const handleDeleteOrUndeleteUser = (userId) => {
    dispatch({ type: EVENT_TYPES.DELETE_USER_TOGGLE_LOADING });
    ManageUsersService.deleteOrUndeleteUser(userId)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.DELETE_USER_TOGGLE_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_USERS_ERROR,
          payload: {
            manageUsersError: message,
          },
        });
      });
  };
  const handleManageUsersError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.MANAGE_USERS_ERROR,
      payload: {
        manageUsersError: errorMsg,
      },
    });
  };

  const handleClearManageUsersErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_USERS_ERRORS });
  };

  const events = {
    onManageUsersError: handleManageUsersError,
    onGetUsersList: handleGetUsersList,
    onGetIhsiUsersList: handleGetIhsiUsersList,
    onClearManageUsersErrors: handleClearManageUsersErrors,
    onUpdateCachedRoles: handleUpdateCachedRoles,
    onAssignUserRoles: handleAssignUserRoles,
    onSuspendOrActivateUser: handleSuspendOrActivateUser,
    onDeleteOrUndeleteUser: handleDeleteOrUndeleteUser,
  };

  return (
    <ManageUsersState.Provider value={state}>
      <ManageUsersDispatch.Provider value={events}>{children}</ManageUsersDispatch.Provider>
    </ManageUsersState.Provider>
  );
};

const useManageUsersState = () => {
  const context = useContext(ManageUsersState);

  if (context === undefined) {
    throw new Error("useManageUsersState must be used within an ManageUsersProvider");
  }

  return context;
};

const useManageUsersDispatch = () => {
  const context = useContext(ManageUsersDispatch);

  if (context === undefined) {
    throw new Error("useManageUsersDispatch must be used within an ManageUsersProvider");
  }

  return context;
};

export { ManageUsersProvider, useManageUsersState, useManageUsersDispatch };
