import React, { createContext, useContext, useReducer } from "react";
import { NotificationService } from "../services";
import { AccountSettingsService } from "../services";
import { saveAs } from "file-saver";

const NotificationState = createContext();
const NotificationDispatch = createContext();

const EVENT_TYPES = {
  UPDATE_NOTIFICATION_TOGGLE_STATUS: "update_notification_toggle_status",
  ADMIN_NOTIFICATION_TOGGLE_LOADING: "admin_notification_toggle_loading",
  ADMIN_NOTIFICATION_TOGGLE_SUCCESS: "admin_notification_toggle_success",
  GET_NOTIFICATION_LOADING: "get_notification_loading",
  GET_NOTIFICATION_SUCCESS: "get_notification_success",
  NOTIFICATION_POPUP_TOGGLE_LOADING: "notification_popup_toggle_loading",
  NOTIFICATION_POPUP_TOGGLE_SUCCESS: "notification_popup_toggle_success",
  CLEAR_NOTIFICATION_ENABLED: "clear_notification_enabled",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE_NOTIFICATION_TOGGLE_STATUS]: (state, event) => {
    const { status } = event.payload;
    return {
      ...state,
      notificationToggleStatus: status,
    };
  },
  [EVENT_TYPES.CLEAR_NOTIFICATION_ENABLED]: (state, event) => {
    return {
      ...state,
      clearNotifications: true,
    };
  },
  [EVENT_TYPES.ADMIN_NOTIFICATION_TOGGLE_LOADING]: (state, event) => {
    return {
      ...state,
      adminNotificationToggleLoading: true,
      adminNotificationToggleSuccess: false,
    };
  },
  [EVENT_TYPES.ADMIN_NOTIFICATION_TOGGLE_SUCCESS]: (state, event) => {
    return {
      ...state,
      adminNotificationToggleLoading: false,
      adminNotificationToggleSuccess: true,
    };
  },
  [EVENT_TYPES.GET_NOTIFICATION_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      getNotificationSuccess: true,
      getNotificationLoading: false,
      notificationData: data,
    };
  },
  [EVENT_TYPES.GET_NOTIFICATION_LOADING]: (state, event) => {
    return {
      ...state,
      getNotificationSuccess: false,
      getNotificationLoading: true,
    };
  },
  [EVENT_TYPES.NOTIFICATION_POPUP_TOGGLE_SUCCESS]: (state, event) => {
    const { accountData } = event.payload;
    return {
      ...state,
      notifyPopupEnabled: accountData.emailNotificationPopup,
      notifyPopupSuccess: true,
      notifyPopupLoading: false,
    };
  },
  [EVENT_TYPES.NOTIFICATION_POPUP_TOGGLE_LOADING]: (state, event) => {
    return {
      ...state,
      notifyPopupSuccess: false,
      notifyPopupLoading: true,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { notificationError } = event.payload;
    return {
      ...state,
      notificationError,
      getNotificationSuccess: false,
      getNotificationLoading: false,
      notifyPopupSuccess: false,
      notifyPopupLoading: false,
      adminNotificationToggleLoading: false,
      adminNotificationToggleSuccess: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      notificationError: "",
      getNotificationSuccess: false,
      getNotificationLoading: false,
      notifyPopupSuccess: false,
      notifyPopupLoading: false,
      adminNotificationToggleLoading: false,
      adminNotificationToggleSuccess: false,
    };
  },
};

const INITIAL_STATE = {
  notificationError: "",
  getNotificationSuccess: false,
  getNotificationLoading: false,
  notifyPopupSuccess: false,
  notifyPopupLoading: false,
  notifyPopupEnabled: false,
  adminNotificationToggleLoading: false,
  adminNotificationToggleSuccess: false,
  notificationData: {},
  clearNotifications: false,
  notificationToggleStatus: false,
};

const HirReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HirReducer, INITIAL_STATE);

  const handleClearNotifications = () => {
    localStorage.setItem("clearNotifications", true);
    dispatch({ type: EVENT_TYPES.CLEAR_NOTIFICATION_ENABLED });
  };
  const handleUpdateNotificationToggleStatus = (status) => {
    dispatch({ type: EVENT_TYPES.UPDATE_NOTIFICATION_TOGGLE_STATUS, payload: { status } });
  };
  const handleToggleNotifyPopup = () => {
    dispatch({ type: EVENT_TYPES.NOTIFICATION_POPUP_TOGGLE_LOADING });
    NotificationService.toggleNotifyPopup()
      .then((data) => {
        AccountSettingsService.getAccountSettings()
          .then((accountData) => {
            dispatch({ type: EVENT_TYPES.NOTIFICATION_POPUP_TOGGLE_SUCCESS, payload: { accountData } });
          })
          .catch(({ message }) => {
            dispatch({
              type: EVENT_TYPES.ERROR,
              payload: {
                notificationError: message,
              },
            });
          });
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            notificationError: message,
          },
        });
      });
  };

  const handleGetNotifications = (size, from) => {
    dispatch({ type: EVENT_TYPES.GET_NOTIFICATION_LOADING });
    NotificationService.getNotifications(size, from)
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.GET_NOTIFICATION_SUCCESS, payload: { data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            notificationError: message,
          },
        });
      });
  };
  const handleAdminNotificationToggle = (userId, isEnabled) => {
    dispatch({ type: EVENT_TYPES.ADMIN_NOTIFICATION_TOGGLE_LOADING });
    NotificationService.adminNotificationToggle(userId, isEnabled)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.ADMIN_NOTIFICATION_TOGGLE_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            notificationError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        notificationError: errorMsg,
      },
    });
  };

  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetNotifications: handleGetNotifications,
    onToggleNotifyPopup: handleToggleNotifyPopup,
    onClearErrors: handleClearErrors,
    onClearNotifications: handleClearNotifications,
    onAdminNotificationToggle: handleAdminNotificationToggle,
    onUpdateNotificationToggleStatus: handleUpdateNotificationToggleStatus,
  };

  return (
    <NotificationState.Provider value={state}>
      <NotificationDispatch.Provider value={events}>{children}</NotificationDispatch.Provider>
    </NotificationState.Provider>
  );
};

const useNotificationState = () => {
  const context = useContext(NotificationState);

  if (context === undefined) {
    throw new Error("useNotificationState must be used within an NotificationProvider");
  }

  return context;
};

const useNotificationDispatch = () => {
  const context = useContext(NotificationDispatch);

  if (context === undefined) {
    throw new Error("useNotificationDispatch must be used within an NotificationProvider");
  }

  return context;
};

export { NotificationProvider, useNotificationState, useNotificationDispatch };
