import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import { Theme } from "../../../theme";
import Avatar from "@mui/material/Avatar";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import Logout from "@mui/icons-material/Logout";
import Badge from "@mui/material/Badge";
import { useAuthState, useAuthDispatch } from "../../../providers/authProvider";
import MenuBook from "@mui/icons-material/MenuBook";
import {
  NotificationProvider,
  useNotificationState,
  useNotificationDispatch,
} from "../../../providers/notificationProvider";
import { StylesContext } from "@mui/styles";
import styles from "./avatarMenu.module.scss";
export default function AvatarMenu(props) {
  const { anchorEl, open, onClose, onClick, isLoggedIn, currentUser } = props;
  const { onLogout } = useAuthDispatch();
  const { clearNotifications } = useNotificationState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isQA, setIsQA] = useState(false);
  const location = useLocation();
  const adminStyles = {
    menu: {
      "&.MuiPaper-root:before": {
        backgroundColor: "#333",
      },
      "& .MuiList-root": {
        "& > li:first-of-type": {
          paddingTop: isAdmin ? "6px" : "1rem",
        },
        "& > li:nth-of-type(2)": {
          marginTop: isAdmin ? ".5rem" : "inherit",
          paddingTop: isAdmin ? "inherit" : "6px",
        },
        paddingTop: 0,
        "&:before": {
          color: "#333",
        },
      },
    },
    // menuItem: {
    //   padding: "1rem",
    // },
    item: {
      backgroundColor: "#333",
      color: "#FFF",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: "#333",
      },
    },
    badgeContainer: {
      backgroundColor: "#EF4720",
    },

    // notificationsIcon: {
    //   "& .MuiBadge-badge": {
    //     top: ".5rem",
    //     backgroundColor: "#ef461f !important",
    //     fill: "#ef461f !important",
    //     color: "#FFF",
    //   },
    // },
  };

  const css = {
    adminMenu: isAdmin ? adminStyles.menu : {},
    adminItem: isAdmin ? adminStyles.item : {},
    badge: {
      ".MuiBadge-badge": {
        top: ".5rem",
        fontSize: ".8rem",
        backgroundColor: "#ef461f",
        color: "#FFF",
      },
    },
  };

  let navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    window.location.reload();
  };

  const handleRoute = (url) => {
    navigate(url);
  };
  useEffect(() => {
    if (currentUser && currentUser.roles) {
      let adminMatchFound = -1;
      adminMatchFound = currentUser.roles.findIndex((element) => element.includes("admin"));
      if (adminMatchFound >= 0) {
        setIsAdmin(true);
      }
    }
  }, [isAdmin]);
  useEffect(() => {
    if (
      window &&
      window.location &&
      window.location.host &&
      window.location.host.indexOf("ihsi-horizonscandb-qa.ecri.org") !== -1
    ) {
      setIsQA(true);
    }
  }, [window.location]);
  return (
    <ThemeProvider theme={Theme}>
      <Menu
        sx={css.adminMenu}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onClose}
        onClick={onClick}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              // eslint-disable-next-line
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundColor: `${isAdmin ? "#333" : "#FFF"}`,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: "9999999",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isAdmin && (
          <MenuItem sx={css.adminItem}>
            <span className={StylesContext.adminLabel}>ADMIN</span>
          </MenuItem>
        )}
        <MenuItem sx={(css.item, { verticalAlign: "middle" })} onClick={() => handleRoute("/myaccount/profile")}>
          <Link className={styles.navLink} to="/myaccount/profile">
            <ListItemIcon sx={css.badgeContainer}>
              <PersonIcon sx={{ color: "#131d2f" }} />
            </ListItemIcon>
            Profile
          </Link>
        </MenuItem>
        {/* <Divider /> */}
        <MenuItem sx={css.item} onClick={() => handleRoute("/dashboard/notifications")}>
          <ListItemIcon sx={css.badgeContainer}>
            <NotificationsIcon sx={{ color: "#131d2f" }} />
          </ListItemIcon>
          Notifications
          {currentUser && currentUser.hasNotifications && currentUser.hasNotifications > 0 && !clearNotifications ? (
            <span className={styles.menuItemBadge}>{currentUser.hasNotifications}</span>
          ) : (
            <></>
          )}
        </MenuItem>

        {currentUser && currentUser.roles.indexOf("admin") >= 0 && (
          <MenuItem sx={(css.item, { verticalAlign: "middle" })} onClick={() => handleRoute("/dashboard/userreports")}>
            <Link className={styles.navLink} to="/dashboard/userreports">
              <ListItemIcon sx={css.badgeContainer}>
                <AssessmentIcon sx={{ color: "#131d2f" }} />
              </ListItemIcon>
              User Reports
            </Link>
          </MenuItem>
        )}
        {/* {currentUser && currentUser.roles.indexOf("QualityCheckViewer") >= 0 && ( */}
        {isQA && (
          <MenuItem sx={(css.item, { verticalAlign: "middle" })}>
            <a
              className={styles.navLink}
              href="https://app.powerbi.com/reportEmbed?reportId=47c2bb69-1bb4-4962-8d5e-3fd59c00d9cd&autoAuth=true&ctid=87f64de7-8ecc-4ac2-b0d6-cf479d6922b5"
              target="_blank"
              rel="noreferrer"
            >
              <ListItemIcon sx={css.badgeContainer}>
                <AssessmentIcon sx={{ color: "#131d2f" }} />
              </ListItemIcon>
              QC Reports
            </a>
          </MenuItem>
        )}
        <MenuItem sx={css.item} onClick={() => handleRoute("/dashboard/savedsearches")}>
          <ListItemIcon sx={css.badgeContainer}>
            <FindInPageIcon sx={{ color: "#131d2f" }} />
          </ListItemIcon>
          Saved Searches
        </MenuItem>
        <MenuItem sx={css.item} onClick={() => handleRoute("/faq")}>
          <ListItemIcon sx={css.badgeContainer}>
            <HelpIcon
              sx={{
                color: "#131d2f",
              }}
            />
          </ListItemIcon>
          FAQs
        </MenuItem>
        <MenuItem sx={css.item} onClick={() => handleRoute("/glossary")}>
          <ListItemIcon sx={css.badgeContainer}>
            <MenuBook
              sx={{
                color: "#131d2f",
              }}
            />
          </ListItemIcon>
          Glossary
        </MenuItem>

        <Divider />
        <MenuItem onClick={handleLogout}>
          <Avatar /> Log Out
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
