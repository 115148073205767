import React, { createContext, useContext, useReducer } from "react";
import { ContactService } from "../services";

const ContactState = createContext();
const ContactDispatch = createContext();

const EVENT_TYPES = {
  UPDATE: "update",
  SUCCESS: "success",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;

    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.SUCCESS]: (state) => {
    return {
      ...state,
      contactSuccess: true,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      contactSuccess: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      contactSuccess: false,
    };
  },
};

const INITIAL_STATE = {
  name: "",
  email: "",
  message: "",
  error: "",
  contactSuccess: false,
};

const ContactReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ContactProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ContactReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };

  const handleContact = () => {
    const { name, email, message } = state;
    ContactService.contact(name, email, message)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onUpdate: handleUpdate,
    onError: handleError,
    onContact: handleContact,
    onClearErrors: handleClearErrors,
  };

  return (
    <ContactState.Provider value={state}>
      <ContactDispatch.Provider value={events}>{children}</ContactDispatch.Provider>
    </ContactState.Provider>
  );
};

const useContactState = () => {
  const context = useContext(ContactState);

  if (context === undefined) {
    throw new Error("useContactState must be used within an ContactProvider");
  }

  return context;
};

const useContactDispatch = () => {
  const context = useContext(ContactDispatch);

  if (context === undefined) {
    throw new Error("useContactDispatch must be used within an ContactProvider");
  }

  return context;
};

export { ContactProvider, useContactState, useContactDispatch };
