import React, { Suspense, lazy, useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate, useParams } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { Theme } from "../../theme";
import { Helmet } from "react-helmet";
import ExportStatusBar from "../ExportStatusBar";
import AdvancedSearchStatusBar from "../Pages/PrivatePages/AdvancedSearchResults/AdvancedSearchStatusBar";
import PrintPreviewStatusBar from "../PrintPreviewStatusBar";
import AlertBar from "../AlertBar";
import Header from "../Header";
import Footer from "../Footer";
import ExportService from "../../services/exportService";
import { useExportState, useExportDispatch } from "../../providers/exportProvider";
import { useAuthState, useAuthDispatch } from "../../providers/authProvider";
import AuthService from "../../services/authService";
import { useHirState, useHirDispatch } from "../../providers/hirProvider";
import { PrintService } from "../../services";
import { usePrintState, usePrintDispatch } from "../../providers/printProvider";
import { useSaveSearchState, useSaveSearchDispatch } from "../../providers/saveSearchProvider";
import {
  NotificationProvider,
  useNotificationState,
  useNotificationDispatch,
} from "../../providers/notificationProvider";
import styles from "./root.module.scss";
import DownloadHir from "../Pages/StaticPages/DownloadHir";
import { USERSNAP_GLOBAL_API_KEY } from "../../providers/constants";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "0abfdc61f38f4045947ce19ba4de4e4cTz03Mjc4NyxFPTE3MjM3NTI1NTcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const AsyncHome = lazy(() => import("../Home" /* webpackChunkName: "home" */));
const AsyncLogin = lazy(() => import("../Pages/LoginPages/Login" /* webpackChunkName: "login" */));
const AsyncRegister = lazy(() => import("../Pages/LoginPages/Register" /* webpackChunkName: "register" */));
const AsyncInvite = lazy(() => import("../Pages/LoginPages/Invite" /* webpackChunkName: "invite" */));
const AsyncDashboard = lazy(() => import("../Pages/PrivatePages/Dashboard" /* webpackChunkName: "dashboard" */));

const AsyncDashboardOverview = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/Overview" /* webpackChunkName: "dashboardOverview" */)
);
const AsyncDashboardNotifications = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/Notifications" /* webpackChunkName: "dashboardNotifications" */)
);
const AsyncDashboardUserReports = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/UserReports" /* webpackChunkName: "dashboardUserReports" */)
);
const AsyncDashboardManageFaqs = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/ManageFaqs" /* webpackChunkName: "dashboardManageFaqs" */)
);

const AsyncDashboardManageGlossary = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/ManageGlossary" /* webpackChunkName: "dashboardManageGlossary" */)
);
const AsyncDashboardManageHirs = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/ManageHirs" /* webpackChunkName: "dashboardManageHirs" */)
);
const AsyncDashboardManageHirTopics = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/ManageHirTopics" /* webpackChunkName: "dashboardManageHirs" */)
);
const AsyncDashboardSavedSearches = lazy(() =>
  import("../Pages/PrivatePages/Dashboard/SavedSearches" /* webpackChunkName: "dashboardUserReports" */)
);
const AsyncMyAccount = lazy(() => import("../Pages/PrivatePages/MyAccount" /* webpackChunkName: "myAccount" */));
const AsyncMyAccountOverview = lazy(() =>
  import("../Pages/PrivatePages/MyAccount/Overview" /* webpackChunkName: "myAccountOverview" */)
);
const AsyncMyAccountProfile = lazy(() =>
  import("../Pages/PrivatePages/MyAccount/Profile" /* webpackChunkName: "myAccountProfile" */)
);
const AsyncResetPassword = lazy(() =>
  import("../Pages/LoginPages/ResetPassword" /* webpackChunkName: "resetPassword" */)
);
const AsyncChangePassword = lazy(() =>
  import("../Pages/LoginPages/ChangePassword" /* webpackChunkName: "changePassword" */)
);
const AsyncSearchResults = lazy(() => import("../Pages/PrivatePages/SearchResults" /* webpackChunkName: "search" */));
const AsyncAdvancedSearchResults = lazy(() =>
  import("../Pages/PrivatePages/AdvancedSearchResults" /* webpackChunkName: "search" */)
);
const AsyncGlossary = lazy(() => import("../Pages/PrivatePages/Glossary" /* webpackChunkName: "glossary" */));

const AsyncCheckEmail = lazy(() => import("../Pages/LoginPages/CheckEmail" /* webpackChunkName: "resetPassword" */));
const AsyncApiTest = lazy(() => import("../ApiTest" /* webpackChunkName: "apiTest" */));
const AsyncContactUs = lazy(() => import("../Pages/StaticPages/ContactUs" /* webpackChunkName: "contactUs" */));
const AsyncAbout = lazy(() => import("../Pages/StaticPages/About" /* webpackChunkName: "about" */));
const AsyncSearchTips = lazy(() => import("../Pages/StaticPages/SearchTips" /* webpackChunkName: "searchTips" */));
const AsyncMemberPage = lazy(() => import("../Pages/PrivatePages/MemberPage" /* webpackChunkName: "memberPage" */));
const AsyncPageNotFound = lazy(() =>
  import("../Pages/StaticPages/PageNotFound" /* webpackChunkName: "pageNotFound" */)
);

const AsyncNotAllowedPage = lazy(() =>
  import("../Pages/LoginPages/NotAllowedPage" /* webpackChunkName: "notAllowedPage" */)
);
const AsyncRecordDetails = lazy(() =>
  import("../Pages/PrivatePages/RecordDetails" /* webpackChunkName: "recordDetails" */)
);

const AsyncHighImpactReports = lazy(() =>
  import("../Pages/PrivatePages/HighImpactReports" /* webpackChunkName: "pageNotFound" */)
);
const AsyncUpcomingHighImpactReports = lazy(() =>
  import("../Pages/PrivatePages/UpcomingHighImpactReports" /* webpackChunkName: "pageNotFound" */)
);
const AsyncHighImpactReportUpload = lazy(() =>
  import("../Pages/PrivatePages/HighImpactReportUpload" /* webpackChunkName: "pageNotFound" */)
);
const AsyncFAQ = lazy(() => import("../Pages/PrivatePages/FAQ" /* webpackChunkName: "asyncfaq" */));

const AsyncDownloadHir = lazy(() => import("../Pages/StaticPages/DownloadHir" /* webpackChunkName: "downloadhir" */));

const Root = (props) => {
  LicenseInfo.setLicenseKey(
    "0abfdc61f38f4045947ce19ba4de4e4cTz03Mjc4NyxFPTE3MjM3NTI1NTcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [pageName, setPageName] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoggedInHome, setIsLoggedInHome] = useState(false);
  const [tokenIsVerified, setTokenIsVerified] = useState(true);
  const [isHirLink, setIsHirLink] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();

  // Auth-related
  const {
    onClearErrors,
    onLogout,
    onError,
    onIsTokenVerified,
    onClearSearchResults,
    onUpdateAdvancedSearchKeywordRows,
    onUpdateSearchRequest,
    onIsDetailedSearchRequestModified,
  } = useAuthDispatch();
  const {
    isLoggedIn,
    error,
    searchData,
    isTokenVerified,
    loginRedirectUrl,
    currentSearchRequest,
    defaultSearchRequest,
    isDetailedSearchRequestModified,
  } = useAuthState();
  const { onGetSavedSearchById, onClearSavedSearchData } = useSaveSearchDispatch();
  const { exportItems, exportSelectAll } = useExportState();
  const { onClearAll } = useExportDispatch();
  const { onGetHir, onHirDownloadById, onSearchHirs, onHirClearAll } = useHirDispatch();
  const { hirError, downloadHirByIdSuccess, hirData, getHirSuccess, getHirLoading, defaultHirSearchRequest } =
    useHirState();
  const { defaultPrintItems, printItems, printPreviewEnabled } = usePrintState();
  const { onUpdatePrintItems, onPrintSelectAll, onPrintClearAll, onUpdatePrintPreview } = usePrintDispatch();
  const { onClearNotifications } = useNotificationDispatch();
  const { getCurrentUser } = AuthService;
  const [exportItemCount, setExportItemCount] = useState();
  const [cachedError, setCachedError] = useState("");
  const [usersnapApi, setUsersnapApi] = useState(null);
  const [showRecordDetailsDisclaimer, setShowRecordDetailsDisclaimer] = useState(false);
  const recordPreviewEnabled = location.pathname.indexOf("/preview") > -1 ? true : false;

  const getLoginRedirect = () => {
    let mainLoginRedirect = "/";
    if (location.pathname !== "/login" || location.pathname !== "/") {
      if (loginRedirectUrl !== undefined) {
        let splitUrl = loginRedirectUrl.split("/");
        if (loginRedirectUrl.indexOf("record") !== -1) {
          splitUrl[splitUrl.length - 1] = ":hssid";
          mainLoginRedirect = splitUrl.join("/");
        }
      }
    }
    return mainLoginRedirect;
  };

  //   api.init();
  //   api.show('a353a7bf-cba7-4846-8613-fcb82dde133d');
  // }
  // var script = document.createElement('script');
  // script.defer = 1;
  // script.src =
  //   'https://widget.usersnap.com/global/load/6295aece-ce51-4825-bd4f-38b3ff8e78d6?onload=onUsersnapCXLoad ';

  // let user = JSON.parse(localStorage.getItem("user"));
  // if (user !== undefined) {
  //   const decodedJwt = parseJwt(user.token);
  //   if (decodedJwt.exp * 1000 > Date.now()) {
  //     document.getElementsByTagName('head')[0].appendChild(script);
  //   }
  // }

  const getUsersnapApi = () => {
    let usersnapApi = null;
    window.onUsersnapCXLoad = function (api) {
      api.init({});
      api.show("a353a7bf-cba7-4846-8613-fcb82dde133d");
      usersnapApi = api;
      setUsersnapApi(api);
    };
    const script = document.createElement("script");
    script.defer = 1;
    script.src = "https://widget.usersnap.com/global/load/6295aece-ce51-4825-bd4f-38b3ff8e78d6?onload=onUsersnapCXLoad";
    document.head.appendChild(script);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }
      script.remove();
    };
  };

  const setDarkModeFlag = (isLoggedIn) => {
    let darkModeFlag = false;
    let routeName = window.location.pathname.replace(/^\/([^/]*).*$/, "$1");
    let recordPathPattern = /^record\//;
    setShowRecordDetailsDisclaimer(false);
    switch (routeName) {
      case "":
        if (isLoggedIn) {
          darkModeFlag = false;
          setIsLoggedInHome(true);
        } else {
          darkModeFlag = true;
          setIsLoggedInHome(false);
        }
        break;
      case "record":
        darkModeFlag = false;
        setIsLoggedInHome(false);
        setShowRecordDetailsDisclaimer(true);
        break;
      case "login":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "register":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "invite":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "resetpassword":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "checkemail":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "notallowed":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "changepassword":
        darkModeFlag = true;
        setIsLoggedInHome(false);
        break;
      case "downloadhir":
        darkModeFlag = false;
        setIsHirLink(true);
        break;
      default:
        darkModeFlag = false;
        setIsLoggedInHome(false);
    }
    setIsDarkMode(darkModeFlag);
  };

  // useEffect(() => {
  //   const newTokenVerified = onIsTokenVerified();
  //   setTokenIsVerified(newTokenVerified);
  //   if (isLoggedIn) {
  //     if (!newTokenVerified) {
  //       setCachedError("Token Expired. Please log in again to continue.");
  //       onLogout();
  //     } else {
  //       setCachedError("");
  //     }
  //   }
  // }, [tokenIsVerified]);

  // Listen for route changes and perform a task

  useEffect(() => {
    if (isLoggedIn) {
      getUsersnapApi();
    }
    //console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
  }, [isLoggedIn]);

  useEffect(() => {
    //error && onClearErrors();
    const newTokenVerified = onIsTokenVerified();
    setTokenIsVerified(newTokenVerified);
    if (isLoggedIn) {
      if (!newTokenVerified) {
        setCachedError("Token Expired. Please log in again to continue.");
        onLogout();
      } else {
        setCachedError("");
      }
    }

    setDarkModeFlag(isLoggedIn);
    if (location.pathname !== "/search") {
      //ExportService._exportItems(JSON.stringify([]));
      onClearAll();
    }
    if (location.pathname !== "/hir") {
      //ExportService._exportItems(JSON.stringify([]));
      onHirClearAll();
    }
    if (location.pathname === "/downloadhir") {
      if (isLoggedIn) {
        let params = new URLSearchParams(location.search);
        let id = params.get("id");
        let title = params.get("title");
        if (title === undefined) {
          title = "HIR Report";
          title = encodeURIComponent(title.substring(0, 250));
        } else {
          title = decodeURIComponent(title);
        }
        onHirDownloadById(id, title);
      }
    }
    const clearAllSearches = () => {
      localStorage.removeItem("detailedSearchRequest");
      localStorage.removeItem("searchRequest");
      onClearSavedSearchData();
      if (location.pathname !== "/search") {
        onClearSearchResults();
      }

      onUpdateAdvancedSearchKeywordRows([]);
      onUpdateSearchRequest({
        size: 20,
        from: 0,
        sortOrder: null,
        sortBy: null,
        searchPhrase: "*",
        //isWildCardSearch: true,
      });
    };
    const expr = location.pathname;
    switch (true) {
      case /record/.test(expr):
        //console.log("Matched record details page");
        break;
      case /advancedsearch/.test(expr):
        //console.log("Matched advanced search  page");
        break;
      case /search/.test(expr):
        // console.log("Matched basic search page");
        break;
      default:
        //console.log("Didn't match any paths - clear all searches.");
        clearAllSearches();
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setDarkModeFlag(isLoggedIn);
  }, [isLoggedIn]);

  const downloadHirFromUrl = () => {
    if (isLoggedIn) {
      let params = new URLSearchParams(location.search);
      let id = params.get("id");
      let title = params.get("title");
      if (title === undefined) {
        title = "HIR Report";
        title = encodeURIComponent(title.substring(0, 250));
      } else {
        title = decodeURIComponent(title);
      }
      onHirDownloadById(id, title);
    }
  };

  const getExportItemCount = () => {
    if (exportSelectAll && searchData && searchData.totalHits) {
      return searchData.totalHits;
    } else {
      return exportItems.length;
    }
  };

  const NavigateWithParams = ({ to, ...rest }) => {
    let redirectPath = getLoginRedirect();
    const params = useParams();
    let toValue;
    if (typeof to === "function") {
      toValue = to(params);
    } else {
      toValue = to;
    }
    return <Navigate to={toValue} {...rest} />;
  };

  const closeHeaderDisclaimer = () => {
    setShowRecordDetailsDisclaimer(false);
  };
  useEffect(() => {
    let itemCount = 0;
    if (exportSelectAll && searchData && searchData.totalHits) {
      itemCount = searchData.totalHits;
    } else {
      itemCount = exportItems.length;
    }
    setExportItemCount(itemCount);
  });

  useEffect(() => {
    let cachedNotificationClearStatus = localStorage.getItem("clearNotifications");
    if (cachedNotificationClearStatus) {
      onClearNotifications();
    }
  }, []);
  return (
    <ThemeProvider theme={Theme}>
      <React.Fragment>
        <Helmet title="IHSI"></Helmet>
        <div
          className={`${styles.pageContainer} ${isDarkMode ? styles.isDarkMode : ""} ${
            isLoggedInHome ? styles.isLoggedInHome : ""
          }`}
        >
          {error && <AlertBar message={`Error: ${error}`} />}
          {cachedError && <AlertBar message={`Error: ${cachedError}`} />}
          {hirError && <AlertBar message={`Error: ${hirError}`} />}
          {downloadHirByIdSuccess && <AlertBar message={"HIR Download Success!"} severity="success" />}

          {recordPreviewEnabled && (
            <div className={styles.notifyBox}>
              <h4>Record Preview Enabled</h4>
            </div>
          )}
          {!recordPreviewEnabled && (
            <Header
              isLoggedIn={isLoggedIn}
              isDarkMode={isDarkMode}
              isLoggedInHome={isLoggedInHome}
              currentUser={getCurrentUser()}
            />
          )}

          <div className={styles.contentWrap}>
            <Suspense fallback={<p></p>}>
              <Routes>
                <Route path="*" element={<AsyncPageNotFound />} />
                <Route
                  path="/myaccount"
                  element={
                    <PrivateRoute>
                      <AsyncMyAccount {...props} />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="/myaccount/profile"
                    element={
                      <PrivateRoute>
                        <AsyncMyAccountProfile {...props} />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <AsyncDashboard {...props} />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="/dashboard/userreports"
                    element={
                      <PrivateRoute allowedRole={["admin", "reportAdmin"]}>
                        <AsyncDashboardUserReports {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/managefaqs"
                    element={
                      <PrivateRoute allowedRole={["admin", "editor"]}>
                        <AsyncDashboardManageFaqs {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/manageglossary"
                    element={
                      <PrivateRoute allowedRole={["admin", "editor"]}>
                        <AsyncDashboardManageGlossary {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/managehirs"
                    element={
                      <PrivateRoute allowedRole={["admin", "editor"]}>
                        <AsyncDashboardManageHirs {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/managehirtopics"
                    element={
                      <PrivateRoute allowedRole={["admin", "editor"]}>
                        <AsyncDashboardManageHirTopics {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/overview"
                    element={
                      <PrivateRoute>
                        <AsyncDashboardOverview {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/dashboard" element={<Navigate replace to="/dashboard/notifications" />} />
                  <Route
                    path="/dashboard/notifications"
                    element={
                      <PrivateRoute>
                        <AsyncDashboardNotifications {...props} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/savedsearches"
                    element={
                      <PrivateRoute>
                        <AsyncDashboardSavedSearches {...props} />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/members"
                  element={
                    <PrivateRoute>
                      <AsyncMemberPage {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/hir"
                  element={
                    <PrivateRoute>
                      <AsyncHighImpactReports {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/upcoming-hir"
                  element={
                    <PrivateRoute>
                      <AsyncUpcomingHighImpactReports {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/invite"
                  element={
                    <PrivateRoute allowedRole="admin">
                      <AsyncInvite {...props} />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/hirupload"
                  element={
                    <PrivateRoute allowedRole="admin">
                      <AsyncHighImpactReportUpload {...props} />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/search"
                  element={
                    <PrivateRoute>
                      <AsyncSearchResults {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/advancedsearch"
                  element={
                    <PrivateRoute>
                      <AsyncAdvancedSearchResults {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/glossary"
                  element={
                    <PrivateRoute>
                      <AsyncGlossary {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/record/:hssId"
                  element={
                    <PrivateRoute>
                      <AsyncRecordDetails isRecordPreview={false} {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/record/preview/:hssId"
                  element={
                    <PrivateRoute>
                      <AsyncRecordDetails isRecordPreview={true} {...props} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/"
                  element={<AsyncHome isLoggedIn={isLoggedIn} currentUser={getCurrentUser()} {...props} />}
                />
                {/* <Route
                  path="/login"
                  element={isLoggedIn ? <Navigate to="/" {...props} replace /> : <AsyncLogin {...props} />}
                /> */}
                <Route
                  path="/faq"
                  element={
                    <PrivateRoute>
                      <AsyncFAQ {...props} />
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<AsyncLogin {...props} />} />

                <Route path="/register" element={<AsyncRegister {...props} />} />
                <Route path="/resetpassword" element={<AsyncResetPassword {...props} />} />
                <Route path="/changepassword" element={<AsyncChangePassword {...props} />} />
                <Route path="/checkemail" element={<AsyncCheckEmail {...props} />} />
                <Route path="/apitest" element={<AsyncApiTest {...props} />} />
                <Route path="/contact" element={<AsyncContactUs {...props} />} />
                <Route path="/about" element={<AsyncAbout {...props} />} />
                <Route path="/searchtips" element={<AsyncSearchTips {...props} />} />
                <Route path="/notallowed" element={<AsyncNotAllowedPage {...props} />} />
                <Route
                  path="/downloadhir"
                  element={
                    <PrivateRoute>
                      <DownloadHir />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
        {!printPreviewEnabled && !recordPreviewEnabled && <Footer />}
        {(exportItems.length > 0 || exportSelectAll) && <ExportStatusBar exportItemCount={exportItemCount} />}
        {isDetailedSearchRequestModified && <AdvancedSearchStatusBar />}
        <PrintPreviewStatusBar />
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Root;
