import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";

const RegisterService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  _invitedUser(user) {
    if (typeof user !== "undefined") {
      localStorage.setItem("invitedUser", JSON.stringify(user));
      return;
    }

    return localStorage.getItem("invitedUser");
  },

  async getInvitedUser(inviteCode) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/registeruser?invitationCode=${inviteCode}`, {
        method: "GET",
        // headers: {
        //   "content-type": "application/json",
        //   authorization: `Bearer ${this._token()}`,
        // },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      this._invitedUser(data);
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async register(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/registeruser`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};

export default RegisterService;
