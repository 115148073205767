import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";
import AuthService from "./authService";

const GlossaryService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,
  _token(token) {
    if (typeof token !== "undefined") {
      localStorage.setItem("token", token);
      return;
    }
    return localStorage.getItem("token");
  },
  async getGlossary() {
    const requestObj = {
      size: 1000,
      from: 0,
      orderBy: "record_section",
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/glossary/getglossaries`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};

export default GlossaryService;
