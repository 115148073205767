import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";

const ContactService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  async contact(formName, formEmail, formMessage) {
    const requestObj = {
      name: formName,
      email: formEmail,
      message: formMessage,
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/contactus`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};

export default ContactService;
