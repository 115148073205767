import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertBar = (props) => {
  const { message, severity, belowHeader = false, onClose = null } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (onClose !== null) {
      onClose();
    }
    setIsOpen(false);
  };

  return (
    <>
      <Snackbar
        sx={{
          top: {
            xs: belowHeader ? "104px" : "",
            sm: belowHeader ? "104px" : "",
            md: belowHeader ? "104px" : "",
            lg: belowHeader ? "104px" : "",
            xl: belowHeader ? "104px" : "",
          },
        }}
        open={isOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        data-testid="alert-bar"
      >
        <Alert onClose={handleClose} severity={severity ? severity : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertBar;
