import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PrintService from "../../services/printService";
import { usePrintState, usePrintDispatch } from "../../providers/printProvider";

import styles from "./printPreviewStatusBar.module.scss";

const css = {
  row: {
    alignItems: "center",
    justifyContent: "center",
  },
  resultsHeading: {
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  buttonPrimary: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  buttonSecondary: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#f7fbff",
    },
  },
};
const PrintPreviewStatusBar = (props) => {
  const { defaultPrintItems, printItems, printPreviewEnabled } = usePrintState();
  const { onUpdatePrintItems, onPrintSelectAll, onPrintClearAll, onUpdatePrintPreview } = usePrintDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isPanelHidden, setIsPanelHidden] = useState(false);
  const [exportType, setPrintType] = useState("csv");

  const handlePrint = () => {
    window.print();
  };

  const handleCancel = () => {
    onUpdatePrintPreview(false);
    onUpdatePrintItems(defaultPrintItems);
  };

  return (
    <>
      <div className={`${styles.printStatusBar} ${!printPreviewEnabled ? styles.hide : ""}`}>
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12">
              <Stack sx={css.row} direction="row" spacing={2} align="center">
                <div className={styles.resultsBox}>
                  <Typography sx={css.resultsHeading} variant="h4">
                    Print Preview Enabled
                  </Typography>
                </div>
                <Button sx={css.buttonPrimary} variant="contained" onClick={() => handlePrint()}>
                  Print
                </Button>
                <Button sx={css.buttonSecondary} variant="outlined" color="primary" onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintPreviewStatusBar;
