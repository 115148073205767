import * as React from "react";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.scss";

const Breadcrumbs = (props) => {
  const { linksArray, isDarkMode } = props;
  return (
    <div className={styles.breadcrumbs} role="presentation">
      <MUIBreadcrumbs aria-label="breadcrumb">
        {linksArray.map((item, index) => {
          if (index !== linksArray.length - 1) {
            return (
              <Link className={styles.bcLink} key={index} to={item.linkUrl}>
                {item.linkLabel}
              </Link>
            );
          }
          return (
            <Typography key={index} color="text.primary">
              {item.linkLabel}
            </Typography>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
