import axios from "axios";
import AuthService from "./authService";
import { fetchWithTimeout } from "./serviceHelpers";

const HirService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,
  _mockUrl: "http://localhost:3001",
  _hirData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirData");
  },
  _hirDashboardTopicsRequest(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirDashboardTopicsRequest", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirDashboardTopicsRequest");
  },
  _hirSearchRequest(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirSearchRequest", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirSearchRequest");
  },
  _hirPreviewRequest(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirPreviewRequest", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirPreviewRequest");
  },
  _hirTopicsRequest(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirTopicsRequest", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirTopicsRequest");
  },
  _hirSearchTopicRequest(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirSearchTopicRequest", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirSearchTopicRequest");
  },
  _hirSearchData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirSearchData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirSearchData");
  },

  _hirSearchTopicData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("hirSearchTopicData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("hirSearchTopicData");
  },
  async hirDownloadById(id) {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(`${this._url}/hir/downloadpreviewhir?HIRId=${id}`, {
          method: "GET",
          headers: {
            "content-type": "application/vnd.restful+json",
            authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },
  async saveHirTopic(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hirtopic/savehirtopic`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateHirTopic(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hirtopic/updatehirtopic`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteHirTopic(id) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hirtopic/deletehirtopic?faqId=${id}`, {
        method: "POST",
        //body: JSON.stringify(id),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async getHirTopics(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hirtopic/gethirtopics`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      this._hirDashboardTopicsRequest(requestObj);
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async getHir(AuthService, skip = 0, take = 10, showDeletedOnly = false) {
    let token = localStorage.getItem("token");
    if (token) {
      try {
        let response = await fetchWithTimeout(
          `${this._url}/hir?skip=${skip}&take=${take}&showDeletedOnly=${showDeletedOnly}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/vnd.restful+json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          if (response.status === 429) {
            throw Error(response.statusText);
          } else if (response.status === 403) {
            throw Error(response.statusText);
          }
          let data = await response.json();
          throw new Error(data.responseCode);
        }

        let data = await response.json();
        //this._hirData(data);
        return data;
      } catch (err) {
        throw new Error(err);
      }
    } else {
      throw new Error("Token not found.");
    }
  },
  async retrieveHirSearchResults(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hir/search`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async retrieveHirPreviewResults(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hir/gethirpreview`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async retrieveHirSearchTopicResults(requestObj) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hirtopic/search`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //this._hirSearchTopicData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async uploadHir(formData) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hir`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async editHir(formData) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hir/updatehir`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async deleteHir(hirIdNum) {
    try {
      let response = await fetchWithTimeout(`${this._url}/hir/delete?Id=${hirIdNum}`, {
        method: "POST",
        //body: hirIdNum,
        headers: {
          authorization: `Bearer ${AuthService._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  getHirSearchData() {
    return JSON.parse(localStorage.getItem("hirSearchData"));
  },
  setCurrentHirSearchRequest(req) {
    this._hirSearchRequest(req);
  },
  setCurrentHirDashboardTopicsRequest(req) {
    this._hirDashboardTopicsRequest(req);
  },
  getCurrentHirDashboardTopicsRequest() {
    return JSON.parse(localStorage.getItem("hirDashboardTopicsRequest"));
  },
  getCurrentHirSearchRequest() {
    return JSON.parse(localStorage.getItem("hirSearchRequest"));
  },
  setCurrentHirPreviewRequest(req) {
    this._hirPreviewRequest(req);
  },
  getCurrentHirPreviewRequest() {
    return JSON.parse(localStorage.getItem("hirPreviewRequest"));
  },
  setCurrentHirSearchTopicRequest(req) {
    this._hirSearchTopicRequest(req);
  },
  getCurrentHirSearchTopicRequest() {
    return JSON.parse(localStorage.getItem("hirSearchTopicRequest"));
  },
};

export default HirService;
