// import withRouter from "../utils/withRouter";

// const parseJwt = (token) => {
//   try {
//     return JSON.parse(atob(token.split(".")[1]));
//   } catch (e) {
//     return null;
//   }
// };

const fetchWithTimeout = async function (resource, options = {}) {
  const { timeout = 30000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

// const checkToken = (req, res) => {
//   const { token } = req.body;

//   // Does the user possess a JWT?
//   if (!token) {
//     return res.status(401).json({ success: false, message: "Must have JWT." });
//   }

//   // Verify the status of user's JWT.
//   jwt.verify(token.replace(/^JWT\s/, ""), (secret = ""), (err, decoded) => {
//     if (err) {
//       // If status = expired, prompt user to login again.
//       if (err.name === "TokenExpiredError") {
//         return res.status(422).json({
//           success: false,
//           expireTime: true,
//           message: "JWT has expired. Please login again, this is for your security!",
//         });
//       }
//       // If this can't be done return error message.
//       return res.status(422).json({
//         success: false,
//         message: "JWT Verification Issue.",
//       });
//     }

//     // Find user in db and generate a new JWT.
//     User.findById(decoded.sub)
//       .then((user) =>
//         res.status(201).json({
//           success: true,
//           message: "JWT Refreshed.",
//           token: `JWT ${generateToken(user)}`,
//           user: setUserInfo(user),
//         })
//       )
//       // If this can't be done return error message.
//       .catch((error) =>
//         res.status(401).json({
//           success: false,
//           message: "JWT Refresh Issue.",
//           error,
//         })
//       );
//   });
// };

export { fetchWithTimeout };
