import axios from "axios";
import AuthService from "./authService";
import { fetchWithTimeout } from "./serviceHelpers";
let token = AuthService._token();
const ManageUsersService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  async getUsersList(requestObj) {
    try {
      let reqQuery = "";

      for (const [key, value] of Object.entries(requestObj)) {
        if (key === "size") {
          reqQuery = reqQuery + `?Size=${value}`;
        }

        if (key === "from") {
          reqQuery = reqQuery + `&From=${value}`;
        }

        if (key === "orderBy") {
          reqQuery = reqQuery + `&OrderBy=${value}`;
        }

        if (key === "sortOrder") {
          reqQuery = reqQuery + `&SortOrder=${value}`;
        }

        if (key === "deleted") {
          reqQuery = reqQuery + `&Deleted=${value}`;
        }
        if (key === "suspended") {
          reqQuery = reqQuery + `&Suspended=${value}`;
        }
      }
      let response = await fetchWithTimeout(`${this._url}/auth/userslist${reqQuery}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();

      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async getIhsiUsersList(requestObj) {
    try {
      let reqQuery = "";

      for (const [key, value] of Object.entries(requestObj)) {
        if (key === "size") {
          reqQuery = reqQuery + `?Size=${value}`;
        }

        if (key === "from") {
          reqQuery = reqQuery + `&From=${value}`;
        }

        if (key === "orderBy") {
          reqQuery = reqQuery + `&OrderBy=${value}`;
        }

        if (key === "sortOrder") {
          reqQuery = reqQuery + `&SortOrder=${value}`;
        }

        if (key === "deleted") {
          reqQuery = reqQuery + `&Deleted=${value}`;
        }
        if (key === "suspended") {
          reqQuery = reqQuery + `&Suspended=${value}`;
        }
      }
      let response = await fetchWithTimeout(`${this._url}/report/usersreport${reqQuery}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();

      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async assignUserRoles(userId, roleId) {
    try {
      let response = await fetchWithTimeout(
        `${this._url}/auth/assignrevokeuserrole?userId=${userId}&roleId=${roleId}`,
        {
          method: "POST",
          //body: JSON.stringify(requestObj),
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async suspendOrActivateUser(userId) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/suspendoractivateuser?userId=${userId}`, {
        method: "POST",
        //body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async deleteOrUndeleteUser(userId) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/deleteuser?userId=${userId}`, {
        method: "POST",
        //body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default ManageUsersService;
