import React, { createContext, useContext, useReducer } from "react";
import { SaveSearchService } from "../services/";

const SaveSearchState = createContext();
const SaveSearchDispatch = createContext();

const EVENT_TYPES = {
  UPDATE_NAME: "update_name",
  SAVE_SEARCH_SUCCESS: "save_search_success",
  SAVE_SEARCH_LOADING: "save_search_loading",
  GET_SAVED_SEARCHES_SUCCESS: "get_saved_searches_success",
  GET_SAVED_SEARCHES_LOADING: "get_saved_searches_loading",
  DELETE_SAVED_SEARCH_LOADING: "delete_saved_search_loading",
  DELETE_SAVED_SEARCH_SUCCESS: "delete_saved_search_success",
  CLEAR_SAVE_SEARCH_ERRORS: "clear_save_search_errors",
  CLEAR_SAVE_SEARCH_DATA: "clear_save_Search_data",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.UPDATE_NAME]: (state, event) => {
    const { name } = event.payload;

    return {
      ...state,
      name: name,
    };
  },
  [EVENT_TYPES.SAVE_SEARCH_SUCCESS]: (state) => {
    return {
      ...state,
      saveSearchSuccess: true,
      saveSearchLoading: false,
    };
  },
  [EVENT_TYPES.SAVE_SEARCH_LOADING]: (state) => {
    return {
      ...state,
      saveSearchSuccess: false,
      saveSearchLoading: true,
    };
  },
  [EVENT_TYPES.GET_SAVED_SEARCHES_SUCCESS]: (state, event) => {
    const { data } = event.payload;
    return {
      ...state,
      savedSearchData: data,
      getSavedSearchesSuccess: true,
      getSavedSearchesLoading: false,
    };
  },
  [EVENT_TYPES.GET_SAVED_SEARCHES_LOADING]: (state) => {
    return {
      ...state,
      getSavedSearchesSuccess: false,
      getSavedSearchesLoading: true,
    };
  },
  [EVENT_TYPES.DELETE_SAVED_SEARCH_SUCCESS]: (state, event) => {
    //const { data } = event.payload;
    return {
      ...state,
      deleteSavedSearchSuccess: true,
      deleteSavedSearchLoading: false,
      deleteSavedSearchId: null,
    };
  },
  [EVENT_TYPES.DELETE_SAVED_SEARCH_LOADING]: (state, event) => {
    const { id } = event.payload;
    return {
      ...state,
      deleteSavedSearchSuccess: false,
      deleteSavedSearchLoading: true,
      deleteSavedSearchId: id,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { saveSearchError } = event.payload;
    return {
      ...state,
      saveSearchError: saveSearchError,
      saveSearchSuccess: false,
      saveSearchLoading: false,
      deleteSavedSearchSuccess: false,
      deleteSavedSearchLoading: false,
      deleteSavedSearchId: null,
    };
  },
  [EVENT_TYPES.CLEAR_SAVE_SEARCH_DATA]: (state) => {
    return {
      ...state,
      savedSearchData: {},
    };
  },

  [EVENT_TYPES.CLEAR_SAVE_SEARCH_ERRORS]: (state) => {
    return {
      ...state,
      name: "",
      saveSearchError: "",
      saveSearchSuccess: false,
      saveSearchLoading: false,
      deleteSavedSearchSuccess: false,
      deleteSavedSearchLoading: false,
      deleteSavedSearchId: null,
    };
  },
};

const INITIAL_STATE = {
  name: "",
  saveSearchError: "",
  savedSearchData: {},
  getSavedSearchesSuccess: false,
  getSavedSearchesLoading: false,
  deleteSavedSearchSuccess: false,
  deleteSavedSearchLoading: false,
  deleteSavedSearchId: null,
  saveSearchSuccess: false,
  saveSearchLoading: false,
};

const SaveSearchReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const SaveSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SaveSearchReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearSavedSearchErrors();
    const name = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE_NAME, payload: { name } });
  };

  const handleSaveSearch = (name) => {
    const searchRequest = localStorage.getItem("searchRequest");

    if (searchRequest === null || searchRequest === undefined) {
      dispatch({
        type: EVENT_TYPES.ERROR,
        payload: {
          saveSearchError: "No search request found",
        },
      });
      return false;
    }

    const requestObj = {
      title: name,
      savedSearchRequest: searchRequest,
    };

    dispatch({ type: EVENT_TYPES.SAVE_SEARCH_LOADING });
    SaveSearchService.saveSearch(requestObj)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_SEARCH_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            saveSearchError: message,
          },
        });
      });
  };
  const handleSaveAdvancedSearch = (name, request) => {
    if (typeof request === "string") {
      request = JSON.parse(request);
    }
    request = Object.assign(request, { isAdvancedSearch: true });
    const searchRequest = JSON.stringify(request);

    if (searchRequest === null || searchRequest === undefined) {
      dispatch({
        type: EVENT_TYPES.ERROR,
        payload: {
          saveSearchError: "No search request found",
        },
      });
      return false;
    }

    const requestObj = {
      title: name,
      savedSearchRequest: searchRequest,
    };

    dispatch({ type: EVENT_TYPES.SAVE_SEARCH_LOADING });
    SaveSearchService.saveSearch(requestObj)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_SEARCH_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            saveSearchError: message,
          },
        });
      });
  };
  const handleGetSavedSearches = () => {
    dispatch({ type: EVENT_TYPES.GET_SAVED_SEARCHES_LOADING });
    SaveSearchService.getSavedSearches()
      .then((data) => {
        setTimeout(() => {
          dispatch({ type: EVENT_TYPES.GET_SAVED_SEARCHES_SUCCESS, payload: { data } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            saveSearchError: message,
          },
        });
      });
  };
  const handleGetSavedSearchById = (id) => {
    dispatch({ type: EVENT_TYPES.GET_SAVED_SEARCHES_LOADING });
    SaveSearchService.getSavedSearchById(id)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.GET_SAVED_SEARCHES_SUCCESS, payload: { data } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            saveSearchError: message,
          },
        });
      });
  };
  const handleDeleteSavedSearch = (id) => {
    dispatch({ type: EVENT_TYPES.DELETE_SAVED_SEARCH_LOADING, payload: { id } });
    SaveSearchService.deleteSavedSearch(id)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.DELETE_SAVED_SEARCH_SUCCESS });
        handleGetSavedSearches();
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            saveSearchError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        saveSearchError: errorMsg,
      },
    });
  };

  const handleClearSavedSearchErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_SAVE_SEARCH_ERRORS });
  };
  const handleClearSavedSearchData = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_SAVE_SEARCH_DATA });
  };

  const events = {
    onUpdate: handleUpdate,
    onError: handleError,
    onSaveSearch: handleSaveSearch,
    onSaveAdvancedSearch: handleSaveAdvancedSearch,
    onDeleteSavedSearch: handleDeleteSavedSearch,
    onGetSavedSearches: handleGetSavedSearches,
    onGetSavedSearchById: handleGetSavedSearchById,
    onClearSaveSearchErrors: handleClearSavedSearchErrors,
    onClearSavedSearchData: handleClearSavedSearchData,
  };

  return (
    <SaveSearchState.Provider value={state}>
      <SaveSearchDispatch.Provider value={events}>{children}</SaveSearchDispatch.Provider>
    </SaveSearchState.Provider>
  );
};

const useSaveSearchState = () => {
  const context = useContext(SaveSearchState);

  if (context === undefined) {
    throw new Error("useSaveSearchState must be used within an SaveSearchProvider");
  }

  return context;
};

const useSaveSearchDispatch = () => {
  const context = useContext(SaveSearchDispatch);

  if (context === undefined) {
    throw new Error("useSaveSearchDispatch must be used within an SaveSearchProvider");
  }

  return context;
};

export { SaveSearchProvider, useSaveSearchState, useSaveSearchDispatch };
